import { useContactsStore } from '@entities/contact-table';
import { EmailButton, PhoneButton } from '@features/contacts';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
import { ReactComponent as GithubIcon } from '@shared/assets/icons/github-profile.svg';
import { ReactComponent as FacebookIcon } from '@shared/assets/icons/iconFacebook.svg';
import { ReactComponent as LinkedinIcon } from '@shared/assets/icons/iconLinkedin.svg';
import { ReactComponent as OpenIcon } from '@shared/assets/icons/iconOpen.svg';
import { ReactComponent as TwitterIcon } from '@shared/assets/icons/iconTwitter.svg';
import { AppRoutes } from '@shared/interfaces';
import { getValidLink } from '@shared/libs/validations';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { Link } from 'react-admin';

const LeftSide: FC<{ contact: IContact; isSidebar: boolean }> = ({ contact, isSidebar }) => {
  const { id, full_name, company, job_title, linkedin_url, twitter_url, github_url, facebook_url } =
    contact;

  const contactsStore = useContactsStore();

  const currentCompany = contactsStore.currentUpdatedContact
    ? contactsStore.currentUpdatedContact.company
    : company;

  const currentFullName = contactsStore.currentUpdatedContact
    ? contactsStore.currentUpdatedContact.full_name
    : full_name;

  const currentJobTitle = contactsStore.currentUpdatedContact
    ? contactsStore.currentUpdatedContact.job_title
    : job_title;

  const [emailExist, setIsEmailExists] = useState(false);
  const [phoneExist, setIsPhoneExists] = useState(false);

  const socialsFullName = [
    { social: 'Linkedin', url: linkedin_url, icon: LinkedinIcon },
    { social: 'Twitter', url: twitter_url, icon: TwitterIcon },
    { social: 'Github', url: github_url, icon: GithubIcon },
    { social: 'Facebook', url: facebook_url, icon: FacebookIcon },
  ];

  const getDirection = () => {
    if (isSidebar && !emailExist && !phoneExist) {
      return 'row';
    }

    if (isSidebar && !emailExist && phoneExist) {
      return 'column-reverse';
    }

    if (isSidebar && emailExist && !phoneExist) {
      return 'column';
    }

    if (!isSidebar && !emailExist && phoneExist) {
      return 'column-reverse';
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      border="1px solid #E7E8EF"
      borderRadius="8px"
      minWidth={460}
      maxWidth={490}
    >
      <Stack direction="column" justifyContent="space-between" p={16}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h3"
            color="text.primary"
            fontWeight={700}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {currentFullName}

            {isSidebar && (
              <Link to={`${AppRoutes.Contacts}/${id}/show`}>
                <IconButton>
                  <OpenIcon />
                </IconButton>
              </Link>
            )}
          </Typography>

          <Stack direction="row" ml={-4} mt={-2}>
            {socialsFullName.map(
              ({ url, social, icon: Icon }) =>
                url && (
                  <Tooltip title={getValidLink(url)} key={social}>
                    <a href={getValidLink(url)} rel="noreferrer" target="_blank">
                      <IconButton sx={{ p: 4 }}>
                        <Icon />
                      </IconButton>
                    </a>
                  </Tooltip>
                )
            )}
          </Stack>
        </Stack>
        <Stack width="100%" gap={8}>
          <Typography variant="subtitle2" color="customGray1.main">
            {currentJobTitle}
          </Typography>
          {currentCompany && (
            <Stack>
              <Typography variant="subtitle1" width="100%" noWrap>
                Working at {/* <Link to={`${AppRoutes.Company}/${contact.id}/show`}> */}
                <Box
                  component="span"
                  sx={{
                    fontWeight: 600,
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    color: 'black',
                  }}
                >
                  {currentCompany}
                </Box>
                {/* </Link> */}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        direction={getDirection()}
        width="100%"
        borderTop="1px solid #E7E8EF"
        gap={16}
        sx={[
          emailExist ||
            (phoneExist && {
              pt: 16,
            }),
          !emailExist &&
            !phoneExist && {
              p: 16,
            },
          phoneExist &&
            !emailExist && {
              pb: 16,
              '& > div:first-of-type': {
                pt: 16,
                borderTop: (theme) => `1px solid ${theme.palette.customGray1.light}`,
              },
            },
        ]}
      >
        <EmailButton
          contactId={id}
          isTable={false}
          setEmailExists={setIsEmailExists}
          isPhoneExists={phoneExist}
        />
        <PhoneButton
          contactId={id}
          isTable={false}
          isEmailExist={emailExist}
          setIsPhoneExists={setIsPhoneExists}
        />
      </Stack>
    </Stack>
  );
};

export default observer(LeftSide);
