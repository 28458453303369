import { SxProps, Theme } from '@mui/material';

const styles: SxProps<Theme> = {
  '&.RaDatagrid-root': {
    height: '100%',
    width: '100%',
  },
  '& .RaDatagrid-tableWrapper': {
    boxShadow: 'none',
    borderTop: 'none',
    height: '100%',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    border: (theme) => `1px solid ${theme.palette.customGray1.light}`,
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: (theme) => theme.palette.customGray1.dark,
    },
    '&::-moz-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '&::-moz-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-moz-scrollbar-thumb': {
      background: (theme) => theme.palette.customGray1.dark,
    },
    '&::-ms-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '&::-ms-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-ms-scrollbar-thumb': {
      background: (theme) => theme.palette.customGray1.dark,
    },
    '& *': {
      scrollbarWidth: 'thin',
      scrollbarColor: (theme) => `${theme.palette.customGray1.dark} transparent`,
    },
  },
  '& .RaDatagrid-table': {
    borderCollapse: 'separate',
    borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
  },
  '& .RaDatagrid-table .RaDatagrid-tbody': {},
  '& .RaDatagrid-headerCell': {
    position: 'sticky',
    zIndex: 1,
    backgroundColor: (theme) => theme.palette.customGray1.light,
    fontWeight: 600,
    fontSize: (theme) => theme.spacing(14),
    px: 16,
    py: 11,
    borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
    ':nth-first-of-type(2)': {
      borderRight: (theme) => `1px solid ${theme.palette.secondary.main}`,
    },
    ':first-of-type': {
      borderTopLeftRadius: 0,
      p: 0,
    },
    ':nth-of-type(2)': {
      pl: 0,
    },

    '&:last-child': {
      borderTopRightRadius: 0,
    },
  },

  '& .MuiTableCell-paddingCheckbox': {
    borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
    px: 16,
    py: 12,
  },
  '& .export-checkbox': {},
  '& .full-name': {
    borderRight: (theme) => `1px solid ${theme.palette.customGray1.light}`,

    maxWidth: 300,
    overflow: 'hidden',
  },
  '& .contacts': {
    maxWidth: '100vw',
    overflow: 'hidden',
  },
  '& .company': {
    maxWidth: 200,
    overflow: 'hidden',
  },
  '& .location': {
    maxWidth: 180,
    overflow: 'hidden',
  },
  '& .industry': {
    maxWidth: 300,
    overflow: 'hidden',
  },
  '& .job-title': {
    maxWidth: 300,
    overflow: 'hidden',
  },
  '& .RaDatagrid-row': {},
  '& .RaDatagrid-row:not(:last-child) .RaDatagrid-rowCell': {
    px: 16,
    py: 12,
    borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
  },
  '& .RaDatagrid-row .RaDatagrid-rowCell': {
    px: 16,
    py: 12,
    //borderBottom: theme => `1px solid ${theme.palette.customGray1.light}`,
    ':first-of-type': {
      px: 0,
      py: 0,
    },
    ':nth-of-type(2)': {
      pl: 0,
    },
  },

  '& .RaDatagrid-row td:first-of-type': {
    // borderLeft: theme => `1px solid ${theme.palette.customGray1.light}`,
  },
  '& .RaDatagrid-row td:last-child': {
    borderRight: (theme) => `1px solid ${theme.palette.customGray1.light}`,
  },
};

export { styles };
