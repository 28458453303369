import { IContact } from '@shared/api/services/contact-service';
import { capitalizeWordsInString } from '@shared/libs';

export const serializeContact = (contact: IContact) => {
  const newContact: IContact = {
    ...contact,
    id: capitalizeWordsInString(contact.Contact_id ?? contact.id),
    full_name: capitalizeWordsInString(contact.Full_name ?? contact.full_name),
    job_title: capitalizeWordsInString(contact.Job_title ?? contact.job_title),
    company: capitalizeWordsInString(contact.Job_company_name ?? contact.company),
    industry: capitalizeWordsInString(contact.Industry ?? contact.industry),
    country: capitalizeWordsInString(contact.Location_country ?? contact.country),
    emailIsOpen: contact.Email_is_open ?? contact.emailIsOpen,
    phoneIsOpen: contact.Phone_is_open ?? contact.phoneIsOpen,
    emails: contact.Emails ?? contact.emails,
    phoneNumbers: contact.Phone_numbers ?? contact.phoneNumbers,
  };

  return newContact;
};
