import { useSubscriptionStore } from '@entities/subscription';
import { Button, Stack, Typography } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { getDateName } from '@shared/libs';
import { getNewDate } from '@shared/libs/getNewDate';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlanStatus } from '@/shared/api/services/plan-service/plan.interfaces';

import { ReactComponent as CheckIcon } from '../../../shared/assets/icons/iconCheck.svg';
import { ReactComponent as ThunderIcon } from '../../../shared/assets/icons/iconThunder.svg';
import Integrations from '../integrations/integrations';
import { useUserProfile } from '../model/user-profile.store';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function RightSide() {
  const userProfile = useUserProfile();
  const subscription = useSubscriptionStore();
  const navigate = useNavigate();

  useEffect(() => {
    flowResult(subscription.getPlans());
    flowResult(userProfile.getProfile());
  }, [subscription, userProfile]);

  // const exportCreditsAmount = userProfile.currentSubscription
  //   ? userProfile.currentSubscription.plan.exportCreditsAmount
  //   : userProfile.currentPlan?.exportCreditsAmount

  // 'Number of users: 1',
  // `${exportCreditsAmount} export credits per month`,
  //   `0 reward credits`,

  let price = userProfile.currentSubscription
    ? userProfile.currentSubscription.plan.price
    : userProfile.currentPlan!.price;
  if (
    !userProfile.currentSubscription ||
    userProfile.currentSubscription?.status === PlanStatus.CANCELED
  ) {
    price = '0';
  }

  const duration = userProfile.currentSubscription
    ? userProfile.currentSubscription.plan.duration
    : userProfile.currentPlan!.duration;

  let planName = '';
  let emailCreditsAmount: number | undefined = 0;
  let phoneCreditsAmount: number | undefined = 0;

  if (userProfile.currentSubscription?.status === 'canceled' && subscription.freePlan) {
    planName = subscription?.freePlan?.name ?? null;

    emailCreditsAmount = subscription.freePlan?.emailCreditsAmount;
    phoneCreditsAmount = subscription.freePlan?.emailCreditsAmount;
  } else {
    planName = userProfile.currentSubscription
      ? userProfile.currentSubscription.plan.name[0].toLocaleLowerCase() +
        userProfile.currentSubscription.plan.name.slice(1)
      : userProfile.currentPlan!.name[0].toLocaleLowerCase() +
        userProfile.currentPlan!.name.slice(1);

    emailCreditsAmount = userProfile.currentSubscription
      ? userProfile.currentSubscription.plan.emailCreditsAmount
      : userProfile.currentPlan?.emailCreditsAmount;

    phoneCreditsAmount = userProfile.currentSubscription
      ? userProfile.currentSubscription.plan.phoneCreditsAmount
      : userProfile.currentPlan?.phoneCreditsAmount;
  }

  const planOptions = [
    `${emailCreditsAmount} email credits per month`,
    `${phoneCreditsAmount} mobile number credits per month`,
  ];

  const dateString = userProfile.currentSubscription
    ? userProfile.currentSubscription?.endDate
    : getNewDate(
        userProfile.currentProfile?.balance
          ? userProfile.currentProfile.balance.lastCreditReset
          : '',
        1
      );
  const endDate = getDateName(dateString ?? '', true);

  const onChangePlanClickHandler = () => {
    navigate(AppRoutes.ProfileSubscription);
  };

  return (
    <Stack justifyContent="space-between" alignItems="flex-start" gap={32} width="100%">
      <Stack gap={32} width="100%" minWidth={460}>
        <Stack gap={16}>
          <Typography variant="h2" color="text.primary">
            Subscription
          </Typography>
          <Stack
            direction="row"
            gap={30}
            p={16}
            // TODO: add color to the theme
            sx={{ backgroundColor: '#C9C1FF' }}
            borderRadius={(theme) => theme.spacing(8)}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack gap={4}>
              <Typography variant="h5" color="text.primary">
                {planName[0].toLocaleUpperCase() + planName.slice(1)} Plan
              </Typography>
              <Typography variant="subtitle2" color="text.primary" maxWidth="100%">
                You are on a {planName} plan and your credits will refresh on {endDate}.
              </Typography>
            </Stack>
            {(subscription.currentSubscription === null ||
              subscription.currentSubscription?.status === PlanStatus.CANCELED) && (
              <Button variant="contained" onClick={onChangePlanClickHandler}>
                <Stack direction="row" gap={10} alignItems="center">
                  <ThunderIcon />
                  <Typography variant="subtitle1" color="primary.contrastText">
                    Change plan
                  </Typography>
                </Stack>
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack minWidth={490} borderRadius="16px" border="1px solid #E7E8EF" width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={9}
          px={16}
          py={12}
          borderBottom="1px solid #E7E8EF"
        >
          <Typography variant="subtitle1" fontWeight={700} color="text.primary">
            Your current {planName} plan included
          </Typography>
        </Stack>

        <Stack direction="column" justifyContent="center" px={16}>
          {planOptions.map((value, index) => (
            <Stack
              key={index}
              direction="row"
              justifyContent="left"
              alignItems="center"
              borderBottom="1px solid #E7E8EF"
              py={16}
              gap={8}
              sx={{ ':last-of-type': { borderBottom: 'none' } }}
            >
              <Stack
                sx={{ backgroundColor: (theme) => theme.palette.secondary.main }}
                borderRadius="50%"
              >
                <CheckIcon />
              </Stack>
              {value}
            </Stack>
          ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={9}
          px={16}
          py={12}
          borderTop="1px solid #E7E8EF"
        >
          <Typography variant="subtitle1" fontWeight={700} color="text.primary">
            Total
          </Typography>
          <Typography variant="subtitle1" fontWeight={700} color="text.primary">
            ${price} / {duration}
          </Typography>
        </Stack>
      </Stack>
      <Integrations />
    </Stack>
  );
}

export default observer(RightSide);
