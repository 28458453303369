import { CheckboxHeader } from '@features/common/inputs/checkbox-header';
import { NoResults } from '@features/no-results/no-results';
import { capitalize, Stack, Tooltip, Typography } from '@mui/material';
import { IFullCompany, ISimpleCompanyId } from '@shared/api/services/company-service';
import { combineLocation } from '@shared/libs';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { Datagrid, FunctionField, useListController } from 'react-admin';

import CompanyNameField from './fields/companynameField';
import ExportCheckboxField from './fields/export-checkbox-field';
import { ICompaniesDataGrid } from './interfaces';
import useCompaniesStore from './model/companies-table.store';
import { styles } from './style';
import ContactsSkeleton from './UI/contacts-skeleton';

const CompaniesDataGrid = forwardRef<HTMLTableElement | null, ICompaniesDataGrid>((_, ref) => {
  const { isFetching, isLoading } = useListController();

  const companiesStore = useCompaniesStore();
  const onRowClickHandler = (record: IFullCompany) => {
    companiesStore.setCurrentCompany(record);
    return '';
  };

  if (isFetching || isLoading) {
    return <ContactsSkeleton />;
  }

  return (
    <Datagrid
      sx={styles}
      bulkActionButtons={false}
      rowClick={(_, _r, record) => onRowClickHandler(record as IFullCompany)}
      stickyHeader={false}
      empty={<NoResults />}
      ref={ref}
    >
      <ExportCheckboxField
        store={companiesStore}
        label={<CheckboxHeader store={companiesStore} />}
        sortable={false}
        cellClassName="export-checkbox"
      />
      <CompanyNameField source="name" label="Company" sortable={false} cellClassName="company" />
      <FunctionField
        source="type"
        label="Type"
        render={(record: ISimpleCompanyId) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.type}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {capitalize(record?.type ?? '')}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
      <FunctionField
        source="primary_role"
        label="Primary role"
        cellClassName="Primary role"
        render={(record: ISimpleCompanyId) => {
          return (
            <Stack direction="column" width="100%">
              <Tooltip title={record?.primary_role}>
                <Typography
                  variant="subtitle2"
                  textOverflow="ellipsis"
                  maxWidth="100%"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {capitalize(record?.primary_role ?? '')}
                </Typography>
              </Tooltip>
            </Stack>
          );
        }}
      />
      <FunctionField
        label="Company Location"
        cellClassName="location"
        render={(record: ISimpleCompanyId) => (
          <Stack direction="column" width="100%">
            <Tooltip title={combineLocation(record)}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {combineLocation(record)}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
      <FunctionField
        label="Short description"
        cellClassName="description"
        render={(record: ISimpleCompanyId) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.short_description}>
              <Typography
                variant="body1"
                maxWidth="100%"
                display="-webkit-box"
                whiteSpace="normal"
                overflow="hidden"
                textOverflow="ellipsis"
                sx={{
                  wordWrap: 'break-word',
                  lineClamp: '3',
                  '-webkit-line-clamp': '3',
                  '-webkit-box-orient': 'vertical',
                }}
              >
                {record?.short_description ?? ''}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
    </Datagrid>
  );
});

export default observer(CompaniesDataGrid);
