import { AppRoutes } from '@shared/interfaces';

import { CheckEmailPage } from '../../pages/auth/check-email';
import { ForgotPasswordPage } from '../../pages/auth/forgot-password';
import { ResetPasswordPage } from '../../pages/auth/reset-password';
import { SignUpPage } from '../../pages/auth/sign-up';
import { VerificationPage } from '../../pages/auth/verification';

const authRoutes = [
  { path: AppRoutes.SignUp, component: SignUpPage },
  { path: AppRoutes.CheckEmail, component: CheckEmailPage },
  { path: AppRoutes.Verification, component: VerificationPage },
  { path: AppRoutes.ForgotPassword, component: ForgotPasswordPage },
  { path: AppRoutes.ResetPassword, component: ResetPasswordPage },
];

export { authRoutes };
