import { IFullCompany, ISimpleCompanyId } from '@shared/api/services/company-service';
import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';

export class CompaniesStore {
  currentCompanies: ISimpleCompanyId[] = [];
  currentCompany: IFullCompany | null = null;
  exportCompanies: { id: string; domain: string }[] = [];
  exportAllSelector = false;
  total = 0;

  constructor() {
    makeAutoObservable(this);
  }

  isInExportQueue(id: string) {
    return Boolean(this.exportCompanies.find((contact) => contact.id === id));
  }

  toggleContactForExport(id: string) {
    const currentContact = this.currentCompanies.find((contact) => contact.id === id);
    if (currentContact && id) {
      this.isInExportQueue(id)
        ? (this.exportCompanies = this.exportCompanies?.filter((contact) => contact.id !== id))
        : this.exportCompanies.push({ id: currentContact.id, domain: currentContact.domain });
    }
    this.exportAllSelector =
      companiesStore.exportCompanies.length === companiesStore.currentCompanies.length;
  }

  toggleSelectAllForExport() {
    this.exportAllSelector = !this.exportAllSelector;
    this.exportCompanies = this.exportAllSelector
      ? this.currentCompanies.map((company) => ({
          id: company.id,
          domain: company.domain,
        }))
      : [];
  }

  setCurrentCompany(company: IFullCompany | null) {
    this.currentCompany = company;
  }

  resetAllExports() {
    this.exportCompanies = [];
    this.exportAllSelector = false;
  }
}

const companiesStore = new CompaniesStore();

const Context = createContext(companiesStore);

const useCompaniesStore = () => useContext(Context);

export default useCompaniesStore;

export { companiesStore };
