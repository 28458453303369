import { Stack } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';

import CurrentCompany from './current-company/current-company';
import EducationHistory from './education-history/education-history';
import WorkHistory from './work-history/work-history';

export const RightSide = ({ contact }: { contact: IContact }) => {
  return (
    <Stack direction="column" gap={32} width="100%" minWidth={460}>
      <WorkHistory contact={contact} />
      <EducationHistory contact={contact} />
      <CurrentCompany contact={contact} />
    </Stack>
  );
};
