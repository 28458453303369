const sliderValues = [
  { value: 0, label: '0/mo' },
  { value: 1, label: '1k/mo' },
  { value: 2, label: '2.5k/mo' },
  { value: 3, label: '5k/mo' },
  { value: 4, label: '10k/mo' },
  { value: 5, label: '25k/mo' },
  { value: 6, label: '50k/mo' },
  { value: 7, label: '100k/mo' },
  { value: 8, label: '250k/mo' },
  { value: 9, label: '500k/mo' },
  { value: 10, label: '750k/mo' },
  { value: 11, label: '1M/mo' },
];

const subtitles = {
  export: 'Export credits',
  email: 'Email credits',
  phone: 'Mobile number credits',
};

const sliderPositions = 12;
const matchValues = [
  0, 1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000, 500000, 750000, 1000000,
];

export { matchValues, sliderPositions, sliderValues, subtitles };
