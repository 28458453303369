import { ICompanyLocation } from '@shared/api/services/company-service';

export const makeStringFromObjectLocationCity = (obj: ICompanyLocation) => {
  let string = '';
  if (obj.city) {
    string += obj.city + ', ';
  }
  if (obj.region) {
    string += obj.region + ', ';
  }
  if (obj.country) {
    string += obj.country + ', ';
  }
  return string;
};
