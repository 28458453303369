import { BackToPeople } from '@features/contacts/pagination/back-to-people';
import { CircularProgress, Stack } from '@mui/material';
import { FetchStates } from '@shared/interfaces';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import AccountInfo from './account-info/account-info';
import CreditsUsage from './credits-usage/credits-usage';
import { useUserProfile } from './model/user-profile.store';
import RightSide from './right-side/right-side';

export const UserProfile = observer(() => {
  const userStore = useUserProfile();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      userStore.updateUserProfileData();
    }, 1200);

    return () => {
      clearTimeout(timeoutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (userStore.fetchState === FetchStates.ERROR) {
  //   userStore.getUserProfileData();
  // }

  if (userStore.fetchState !== FetchStates.DONE) {
    return (
      <Stack width="100%" height="100vh" alignItems="center" justifyContent="center">
        <CircularProgress size={130} />
      </Stack>
    );
  }
  //height="100vh" m="0 auto" maxWidth={1256} width="100%" px={20}>

  return (
    <Stack pt={16} maxWidth={1300} gap={16} m="0 auto" width="100%" px={20}>
      <BackToPeople />
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={32}>
        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" gap={32}>
          <AccountInfo />
          <CreditsUsage />
        </Stack>
        <RightSide />
      </Stack>
    </Stack>
  );
});
