import { useSubscriptionStore } from '@entities/subscription';
import { Grid, Stack } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { AppRoutes } from '@shared/interfaces';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { AxiosError } from 'axios';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import TotalPriceConfirmation from '../total-price-confirmation';
import { handlePayment } from './subscription-utils.js';

const StripeForm = ({
  refIsShouldStopUpdating,
}: {
  refIsShouldStopUpdating: React.MutableRefObject<boolean>;
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();
  const notify = useNotify();
  const userStore = useUserProfile();
  const subscription = useSubscriptionStore();

  const [isProgress, setIsProgress] = useState<boolean>(true);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element?.on('ready', () => {
        setIsProgress(false);
      });
    }
  }, [elements]);

  // TODO store data in url
  useEffect(() => {
    if (subscription.selectedPlan) return;
    notify('You need select plan after reload payment page', { type: 'warning' });
    navigate(AppRoutes.ProfileSubscription);
  }, [navigate, notify, subscription.selectedPlan]);

  //TODO move to subscription.models
  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !elements) return;
    refIsShouldStopUpdating.current = true;
    setIsProgress(true);
    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }
      const paymentMethod = await stripe
        ?.createPaymentMethod({
          elements,
        })
        .catch((e) => {
          refIsShouldStopUpdating.current = false;
          throw e;
        });
      if (paymentMethod.error) {
        throw paymentMethod.error;
      }
      await handlePayment(
        stripe,
        elements,
        paymentMethod.paymentMethod.id,
        true,
        subscription,
        userStore,
        navigate,
        notify,
        setIsProgress,
        refIsShouldStopUpdating
      ).catch((e) => {
        console.log(e);
        throw e;
      });
      refIsShouldStopUpdating.current = false;
    } catch (e: unknown) {
      console.log(e);
      refIsShouldStopUpdating.current = false;
      setIsProgress(false);
      let message;
      if (e instanceof AxiosError) {
        message = e.response?.data.message;
      } else if (e instanceof Error || (e && typeof e === 'object' && 'message' in e)) {
        message = e.message;
      } else {
        message = 'Something went wrong, try later';
      }
      notify(message, { type: 'error' });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.response?.data.stringCode === 'payment_failed') {
        await flowResult(subscription.cancel());
      }
    }
    refIsShouldStopUpdating.current = false;
  };
  const price = {
    plan: subscription.selectedPlan?.price ?? '0',
    email: String(subscription.entityExtendedPrices.email.price),
    phone: String(subscription.entityExtendedPrices.phone.price),
    total: subscription.totalExtendedPrice,
    symbol: subscription.currencySymbol,
    name: subscription.selectedPlan?.name ?? '',
  };
  return (
    <Grid
      container
      component="form"
      columnGap={80}
      rowGap={20}
      width="100%"
      padding="0 10px"
      justifyContent="center"
      m="0 auto"
      onSubmit={onSubmit}
    >
      <Grid item xl={3} lg={4} md={5} sm={8} xs={10}>
        <Stack gap={20}>
          <PaymentElement
            options={{
              wallets: {
                googlePay: 'never',
                applePay: 'never',
              },
              terms: {
                auBecsDebit: 'never',
                bancontact: 'never',
                card: 'always',
                ideal: 'never',
                sepaDebit: 'never',
                sofort: 'never',
                usBankAccount: 'never',
              },
              paymentMethodOrder: ['card'],
            }}
          />
          <AddressElement
            options={{
              mode: 'billing',
              display: { name: 'split' },
              defaultValues: {
                address: {
                  state: 'CA',
                  country: 'US',
                },
              },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xl={3} lg={4} md={5} sm={8} xs={10}>
        <TotalPriceConfirmation
          isProgress={isProgress}
          stripe={stripe}
          elements={elements}
          price={price}
        ></TotalPriceConfirmation>
      </Grid>
    </Grid>
  );
};

export default observer(StripeForm);
