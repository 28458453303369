import { useCompanyFilter } from '@features/companies/filters/hooks/filter-context';
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  Stack,
  SxProps,
  TextField,
} from '@mui/material';
import { companiesService } from '@shared/api/services/company-service';
import { makeStringFromObjectLocation } from '@shared/libs/locations/makeStringFromObjectLocation';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { ReactComponent as Close } from '../../../../shared/assets/icons/close-filter-icon.svg';
import { ReactComponent as Down } from '../../../../shared/assets/icons/gray-arrow-bottom.svg';
import { ReactComponent as Search } from '../../../../shared/assets/icons/search-filter.svg';
import { CompanyFilterProps } from '../interfaces';

const CountryCodeInput: FC<CompanyFilterProps> = ({
  locationList,
  processedLocationList,
  setLocationList,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setFilterInputs, filters } = useCompanyFilter();
  const [inputValue, setInputValue] = useState<string>('');
  const [debouncedValue] = useDebounce(inputValue, 1000);

  useEffect(
    () => {
      (async function () {
        if (
          debouncedValue &&
          inputValue &&
          !filters?.region &&
          !filters?.country_code &&
          !filters?.country_code_id &&
          !isLoading
        ) {
          setIsLoading(true);
          const data = await companiesService.getLocationList(debouncedValue);
          setLocationList?.(data);
          setIsLoading(false);
        }
      })();
    },
    //hook should not call when inputValue changes
    // eslint-disable-next-line
    [
      debouncedValue,
      filters?.country_code,
      filters?.country_code_id,
      filters?.region,
      setLocationList,
    ]
  );

  const inputSx: SxProps = {
    '& .MuiInputBase-root': { p: 1 },
    '& .MuiOutlinedInput-input': {},
    '& .MuiOutlinedInput-root': {
      pl: filters?.country_code_id ? 8 : 30,
    },
  };

  const currentLocation = makeStringFromObjectLocation(filters);

  return (
    <Autocomplete
      popupIcon={
        <Stack direction="row" py={6} px={4} justifyContent="center" alignItems="center">
          {isLoading ? <CircularProgress size="20px" /> : <Down />}
        </Stack>
      }
      clearIcon={<Close />}
      clearOnBlur={false}
      disabled={isLoading}
      value={
        processedLocationList?.find((value) => filters?.country_code_id === value.id) ||
        (currentLocation && {
          id: '',
          value: currentLocation,
        }) ||
        null
      }
      onChange={(_, newValue: { id: string; value: string } | null) => {
        if (newValue === null) {
          setFilterInputs?.((prev) => ({
            ...prev,
            city: null,
            region: null,
            country: null,
            country_code: null,
            description: null,
            country_code_id: null,
          }));
          setInputValue('');
          return;
        }
        const newId = (newValue?.value.trim() === '' ? null : newValue?.id) ?? null;
        let obj = { country_code_id: newId };
        if (newId) {
          const city = locationList?.cities.find((el) => el.id === newId);
          if (city) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj = { ...obj, city: city.city, region: city.region, country: city.country };
          } else {
            const region = locationList?.regions.find((el) => el.id === newId);
            if (region) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              obj = { ...obj, region: region.region, country: region.country };
            } else {
              const country = locationList?.countries.find((el) => el.id === newId);
              if (country) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj = { ...obj, country_code: country.country_code, country: country.description };
              }
            }
          }
        }
        setFilterInputs?.((prev) => ({
          ...prev,
          ...obj,
        }));
        setInputValue(
          processedLocationList?.find((value) => filters?.country_code_id === value.id)?.value ||
            inputValue
        );
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onReset={() => {
        setFilterInputs?.((prev) => ({
          ...prev,
          city: null,
          region: null,
          country: null,
          country_code: null,
          description: null,
          country_code_id: null,
        }));
      }}
      options={isLoading ? [] : processedLocationList!}
      autoHighlight
      getOptionLabel={(option) => option.value}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderOption={({ key, ...props }, option) => (
        <MenuItem
          value={option.id}
          {...props}
          key={option.id}
          sx={{ fontSize: (theme) => theme.typography.subtitle2 }}
        >
          {option.value}
        </MenuItem>
      )}
      renderInput={(params) => (
        <>
          <Box sx={{ position: 'relative' }}>
            <TextField
              {...params}
              placeholder="Search by location"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              sx={inputSx}
            />
            {!filters?.country_code_id && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '7%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Search />
              </Box>
            )}
          </Box>
        </>
      )}
      {...props}
    />
  );
};

export default observer(CountryCodeInput);
