import { Stack, Typography } from '@mui/material';
import { ReactComponent as BlackMainIcon } from '@shared/assets/icons/iconMailBlack.svg';
import { useRef } from 'react';

import { CopyIconButton } from '../../../shared/ui/buttons';

export function EmailCompanyInProfile({
  email,
  onCopyEmail,
  title,
}: {
  email: string;
  onCopyEmail: (value: string) => void;
  title: string;
}) {
  const numberRef = useRef<null | HTMLParagraphElement>(null);

  const onEmailClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!numberRef.current) return;
    const range = document.createRange();
    range.selectNodeContents(numberRef.current);
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <Stack direction="row" alignItems="center" gap={8}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => {
          return {
            width: 44,
            height: 44,
            backgroundColor: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.customGray1.light}`,
            borderRadius: '50%',
          };
        }}
      >
        <BlackMainIcon />
      </Stack>
      <Stack>
        <Stack direction="row" alignItems="center" gap={8}>
          <Typography
            variant="subtitle2"
            color="primary.light"
            ref={numberRef}
            onClick={onEmailClickHandler}
          >
            {email}
          </Typography>
          <CopyIconButton title="Copy email" onClick={() => onCopyEmail(email)} />
        </Stack>
        <Typography variant="subtitle2" color="customGray2.dark">
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
}
