import UpdateWrapper from '@entities/update-wrapper/update-wrapper';
import { BackToCompanies } from '@features/companies/pagination/back-to-companies';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
import { AppRoutes } from '@shared/interfaces';
import { FC, useState } from 'react';
import { useGetOne } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';

import LeftSide from './left-side/left-side';
import { RightSide } from './right-side/right-side';

export const ContactProfile: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [updating, setUpdating] = useState(false);

  const { data, isLoading } = useGetOne<IContact>(
    'contacts',
    { id: id! },
    { onError: () => navigate(AppRoutes.Contacts) }
  );
  if (isLoading) {
    return (
      <Stack width="100%" height="40vh" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <UpdateWrapper setUpdating={setUpdating} isOpen={true}>
      <Stack direction="column" justifyContent="left" m={40} gap={16} height="100vh">
        <BackToCompanies />
        {updating && (
          <Stack direction="row" gap={8} alignItems="center">
            <Typography variant="subtitle1">Updating...</Typography>
            <CircularProgress size={20} />
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={32}>
          <LeftSide contact={data!} isSidebar={false} />
          <RightSide contact={data!} />
        </Stack>
      </Stack>
    </UpdateWrapper>
  );
};
