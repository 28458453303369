export const makeStringFromObjectLocationCountry = (obj: {
  country_code: string;
  description: string;
}) => {
  let string = '';
  if (obj.description) {
    string += obj.description + ', ';
  }
  if (obj.country_code) {
    string += obj.country_code + ', ';
  }
  return string;
};
