import ManageMailboxButton from '@features/emails/buttons/manage-mailbox-button';
import CreateMailboxModal from '@features/emails/modal/create-mailbox-modal';
import { Stack } from '@mui/system';
import { IContactDataLayout } from '@widgets/data-layouts/types';
import { useState } from 'react';

import DataLayout from './data-layout';

const EmailsLayout = ({ children, showSaved }: IContactDataLayout) => {
  const [openCreateMailboxModal, setOpenCreateMailboxModal] = useState(false);

  return (
    <DataLayout
      header={
        <Stack direction="row" display="flex" align-items="flex-end">
          <ManageMailboxButton openMailbox={() => setOpenCreateMailboxModal(true)} />
          <CreateMailboxModal
            open={openCreateMailboxModal}
            setOpen={setOpenCreateMailboxModal}
          ></CreateMailboxModal>
        </Stack>
      }
      filter={null}
      type={'emails'}
      show={showSaved}
    >
      {children}
    </DataLayout>
  );
};

export default EmailsLayout;
