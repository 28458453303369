import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';

//import { useUserProfile } from '../../../pages/user-profile/model/user-profile.store'

const StyledProgressBar = ({ usedAmount, amount }: { usedAmount: number; amount: number }) => {
  //const userStore = useUserProfile()

  // const getUsedWidth = () => {
  //   if (!isEmailStats || !userStore.currentSubscription) return -(usedAmount * 100) / amount
  //   return 100
  // }

  const usedWidth = 100 - (usedAmount * 100) / amount;

  return (
    <Stack
      sx={{
        backgroundColor: (theme) => theme.palette.customGray1.light,
      }}
      borderRadius={(theme) => theme.spacing(16)}
      height={(theme) => theme.spacing(16)}
      width="100%"
      justifyContent="left"
      direction="row"
      overflow="hidden"
    >
      <Stack
        direction="row"
        width={`${usedWidth}%`}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
        borderRadius={(theme) => theme.spacing(16)}
        height="100%"
      ></Stack>
    </Stack>
  );
};

export default observer(StyledProgressBar);
