import { Stack, Typography } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { useEffect } from 'react';
import { Link } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { LoginForm } from '../../../features/auth/login-from';
import authProvider from '../../../providers/authProvider';
import { AuthFlowLayout } from '../../../shared/ui/auth-flow-layout';
//import { GoogleButton } from '../../../shared/ui/buttons'
//import { AuthDivider } from '../../../shared/ui/dividers'

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated: boolean = authProvider.isAuthenticated();
    if (!isAuthenticated) return;
    navigate(AppRoutes.Base);
  }, [navigate]);

  return (
    <AuthFlowLayout>
      <LoginForm />
      {/* <Box my={18}>
        <AuthDivider />
      </Box>
      <GoogleButton title="Login with Google" /> */}
      <Stack justifyContent="center" alignItems="center" mt={25}>
        <Typography variant="subtitle2">
          {"Don't have an account?"}{' '}
          <Typography component="span" variant="subtitle2" fontWeight={600} color="primary.main">
            <Link to={AppRoutes.SignUp}>Sign up for free</Link>
          </Typography>
        </Typography>
      </Stack>
    </AuthFlowLayout>
  );
};

export default LoginPage;
