export const createSliderValues = (defaultCount: number, length: number, duration: string) => {
  const divider = defaultCount.toString().length >= 4 ? 1000 : 1;
  const sliderValues = [{ value: 0, label: `0/${duration}` }];
  for (let i = sliderValues.length; i <= length; i++) {
    sliderValues.push({
      value: i,
      label: `${
        defaultCount
          ? (defaultCount / divider) % 1 === 0
            ? (defaultCount / divider) * i
            : ((defaultCount / divider) * i).toFixed(1)
          : 0
      }${divider === 1000 ? 'k' : ''}/${duration}`, //+(defaultCount / 1000).toFixed(2)
    });
  }
  return sliderValues;
};

export const createMatchValues = (amount: number, length: number) => {
  const matchValues = [0];
  for (let i = matchValues.length; i <= length; i++) {
    matchValues.push((amount ?? 0) * i);
  }
  return matchValues;
};
