import { Stack, Tooltip, Typography } from '@mui/material';
//import { Link } from 'react-admin'
//import { AppRoutes } from '../../../shared/interfaces'
import { capitalizeWordsInString } from '@shared/libs';
import { DotDivider } from '@shared/ui/dividers';

const Experience = ({
  place,
  role,
  endDate,
  startDate,
}: {
  place: string;
  role: string;
  endDate?: string;
  startDate?: string;
}) => {
  const startYear = startDate
    ? startDate.length === 4
      ? startDate
      : new Date(startDate).getFullYear()
    : '';
  const endYear = endDate ? (endDate.length === 4 ? endDate : new Date(endDate).getFullYear()) : '';

  return (
    <Stack
      width="100%"
      direction="column"
      justifyContent="space-between"
      gap={16}
      p={16}
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.customGray1.light}`,
        ':first-child': {
          borderTop: 'none',
        },
      }}
    >
      <Stack width="100%" direction="column">
        {/* <Link to={`${AppRoutes.Company}/${contactId}/show`}> */}

        <Tooltip title={capitalizeWordsInString(place)}>
          <Typography
            variant="subtitle1"
            color="text.primary"
            textOverflow="ellipsis"
            maxWidth="100%"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {capitalizeWordsInString(place)}
          </Typography>
        </Tooltip>
        <Stack direction="row" gap={role && 8} alignItems="center">
          <Tooltip title={capitalizeWordsInString(role)}>
            <Typography
              variant="subtitle2"
              color="customGray1.main"
              textOverflow="ellipsis"
              maxWidth="96%"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {capitalizeWordsInString(role)}
            </Typography>
          </Tooltip>
          {role && startDate && <DotDivider />}
          {startDate && (
            <>
              <Typography variant="subtitle2" color="customGray2.dark">
                {endYear ? `${startYear} - ${endYear}` : `${startYear} - Current`}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Experience;
