import { IconButton } from '@mui/material';
import { FC, useState } from 'react';

import { ReactComponent as EyeClose } from '../../assets/icons/eyeClose.svg';
import { ReactComponent as EyeOpen } from '../../assets/icons/eyeOpen.svg';
import InputField from './input-field';
import { IInputField } from './types';

const PasswordField: FC<IInputField> = ({ ...rest }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const EyeIcon = isPasswordVisible ? <EyeOpen /> : <EyeClose />;

  const onEyeClickHandler = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <InputField
      {...rest}
      type={isPasswordVisible ? 'text' : 'password'}
      endAdornment={<IconButton onClick={onEyeClickHandler}>{EyeIcon}</IconButton>}
    />
  );
};

export default PasswordField;
