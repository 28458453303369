import { Stack, Typography } from '@mui/material';
import { capitalizeWordsInString } from '@shared/libs';
import { FC } from 'react';

import { ReactComponent as CheckIcon } from '../../../shared//assets/icons/check-icon-with-bg.svg';

const CreditList: FC<{ email: number; phone: number; duration: string }> = ({
  //exportC,
  email,
  phone,
  duration,
}) => {
  const credits = [
    `${email} email credits / `,
    `${phone} mobile credits / `,
    //`${exportC} records / `,
  ].map((row) => capitalizeWordsInString(row));

  return (
    <Stack component="ul" gap={16}>
      {credits.map((credit) => (
        <Stack direction="row" gap={8} alignItems="center" component="li" key={credit}>
          <CheckIcon />
          <Typography variant="subtitle2">
            {credit}
            <Typography
              component="span"
              color="text.secondary"
              variant="subtitle2"
              fontWeight={500}
              fontStyle="italic"
            >
              {duration}
            </Typography>
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default CreditList;
