import { AxiosInstance } from 'axios';

import withAuthorization from '../../withAuth';
import { Plan } from '../subscription-service/subscription.interfaces';

class PlanService {
  private requestInstance: AxiosInstance;

  constructor(requestInstance: AxiosInstance) {
    this.requestInstance = requestInstance;
  }

  async getActivePlan(): Promise<Plan[]> {
    const { data } = await this.requestInstance.get<Plan[]>('plan/active');

    return data;
  }
}

export const planService = new PlanService(withAuthorization);
