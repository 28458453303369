import { Box, Typography } from '@mui/material';
import { ReactComponent as DevelopmentIcon } from '@shared/assets/icons/in_development.svg';

export const InDevelopment = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <DevelopmentIcon width={400} style={{ height: 'auto', margitTop: '-100px' }} />
      <Typography align="center" variant="h3">
        This page is in development
      </Typography>
    </Box>
  );
};
