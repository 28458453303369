// import {
//   monthPrice,
//   yearPrice,
// } from '../../../features/subscription/credit-slider/credit-slider.meta'
import { IPlan, PlanStatus } from '@shared/api/services/plan-service/plan.interfaces';
import { planService } from '@shared/api/services/plan-service/plan.service';
import {
  ClientSecret,
  ExtendedPricesDto,
  SubscribeRequest,
  SubscribeRequestSliderValues,
  Subscription,
} from '@shared/api/services/subscription-service/subscription.interfaces';
import { subscriptionService } from '@shared/api/services/subscription-service/subscription.service';
import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';

type State = 'pending' | 'done' | 'error';

const emptySliderValues: SliderValues = {
  phone: { value: 0, count: 0 },
  email: { value: 0, count: 0 },
  export: { value: 0, count: 0 },
};
const emptyExtendedPrices: EntityExtendedPrices = {
  phone: { price: 0 },
  email: { price: 0 },
  export: { price: 0 },
};

export interface SliderValues {
  phone: { value: number; count: number };
  email: { value: number; count: number };
  export: { value: number; count: number };
}

export interface EntityExtendedPrices {
  phone: { price: number };
  email: { price: number };
  export: { price: number };
}
class SubscriptionStore {
  monthPrice = 40;
  yearPrice = 400;
  monthEmails = 1000;
  yearEmails = 12000;
  monthPhones = 100;
  yearPhones = 1200;

  plans: IPlan[] = [];

  freePlan: IPlan | undefined = undefined;

  state: State = 'done';

  isMonthPlans = true;

  currentSubscription: Subscription | null = null;

  selectedPlan: IPlan | null = null;

  isSelectedPlanSubscribed = false;

  isChangePlan = false;

  extendedPriceDifference = 0;

  entityExtendedPrices: EntityExtendedPrices = emptyExtendedPrices;

  subscribedPlanSilderValues: SliderValues = emptySliderValues;

  sliderValues: SliderValues = emptySliderValues;

  totalExtendedPrice = '';
  readonly paymentMode = 'payment';
  readonly currency = 'usd';
  readonly currencySymbol = '$';
  constructor() {
    makeAutoObservable(this);
  }

  get sliderPrices() {
    const subPrice = this.selectedPlan?.duration === 'year' ? this.yearPrice : this.monthPrice;

    const subscribedPlanPrices = Object.values(this.subscribedPlanSilderValues);

    const prices = Object.values(this.sliderValues)
      .map(
        (obj, index) =>
          (obj =
            obj.count === 9999999 ? 0 : (obj.value - subscribedPlanPrices[index].value) * subPrice)
      )
      .reduce((prev, curr) => prev + curr, 0);

    return prices;
  }

  setSliderValue(name: keyof SliderValues, value: number, count: number) {
    this.sliderValues[name] = { value, count };
  }

  async getExtendedPrices(
    extendedCredits: ExtendedPricesDto,
    requestId: number,
    currentRequestId: React.MutableRefObject<number>
  ) {
    const prices = await subscriptionService.getExtendedPrices(extendedCredits);
    if (currentRequestId.current < requestId) {
      currentRequestId.current = requestId;
      this.entityExtendedPrices.phone.price = prices.addPhonePrice;
      this.entityExtendedPrices.email.price = prices.addEmailPrice;
      this.entityExtendedPrices.export.price = prices.addExportPrice;
      this.totalExtendedPrice = prices.price;
      this.setExtendedPriceDifference();
      return prices;
    }
    return null;
  }

  setExtendedPriceDifference() {
    this.extendedPriceDifference = Object.values(this.entityExtendedPrices).reduce(
      (sum, item) => sum + item.price,
      0
    );
  }

  setSubscribedPlanSilderValue(name: keyof SliderValues, value: number, count: number) {
    this.subscribedPlanSilderValues[name] = { value, count };
  }

  deleteSlidersValues() {
    this.subscribedPlanSilderValues = emptySliderValues;
    this.sliderValues = emptySliderValues;
  }

  toggleIsMonthPlans() {
    this.isMonthPlans = !this.isMonthPlans;
    const switchedPlan = this.plans.find(
      (plan) =>
        plan.grade === this.selectedPlan?.grade &&
        plan.duration === (this.isMonthPlans ? 'month' : 'year')
    );

    if (switchedPlan) {
      if (this.currentSubscription && this.setSelectedPlanIsSubscribed(switchedPlan)) {
        return this.setSelectedPlan(this.currentSubscription.plan);
      }
      return this.setSelectedPlan(switchedPlan);
    }
  }

  setSelectedPlan(plan: IPlan | null) {
    if (plan) {
      this.setSelectedPlanIsSubscribed(plan);
      this.selectedPlan = plan;
    }
  }

  setSelectedPlanIsSubscribed(plan: IPlan) {
    if (!this.currentSubscription) return false;

    this.isSelectedPlanSubscribed =
      this.currentSubscription.plan.grade === plan.grade &&
      this.currentSubscription.plan.duration === plan.duration;
    return this.isSelectedPlanSubscribed;
  }

  checkSelectedPlanIsSubscribed(plan: IPlan) {
    if (!this.currentSubscription) return false;

    if (this.currentSubscription.status === PlanStatus.CANCELED) {
      return plan.grade === 'standard';
    }

    return (
      this.currentSubscription.plan.grade === plan.grade &&
      this.currentSubscription.plan.duration === plan.duration
    );
  }

  getIsSelectedPlan(plan: IPlan) {
    if (!this.selectedPlan) return false;

    return this.selectedPlan.grade === plan.grade && this.selectedPlan.duration === plan.duration;
  }

  getSliderValueToSubscribe() {
    const sliderValues: SubscribeRequestSliderValues = {
      ...(this.sliderValues.email.count !== 0 && { email: this.sliderValues.email.count }),
      ...(this.sliderValues.phone.count !== 0 && { phone: this.sliderValues.phone.count }),
      ...(this.sliderValues.export.count !== 0 && { export: this.sliderValues.export.count }),
    };
    return sliderValues;
  }

  get filteredPlans() {
    const duration = this.isMonthPlans ? 'month' : 'year';
    return this.plans.filter(({ duration: dur }) => dur === duration);
  }

  resetSelectedPlan() {
    this.selectedPlan = null;
  }

  *getPlans() {
    this.state = 'pending';
    try {
      this.plans = yield planService.getActivePlan();
      this.freePlan = this.plans.find(
        (plan: IPlan) => plan.grade === 'standard' && plan.duration === 'month'
      );
      const subscription: string | Subscription =
        yield subscriptionService.getCurrentSubscription();
      this.currentSubscription = typeof subscription === 'string' ? null : subscription;
      const rootPlan = this.plans.find(
        (plan) => plan.id === this.currentSubscription?.plan.basePlan?.id
      );
      const selectedPlan = rootPlan || this.currentSubscription?.plan || null;
      this.setSelectedPlan(selectedPlan);

      this.plans
        .sort((a, b) => {
          return parseInt(a.price) - parseInt(b.price);
        })
        .map((plan) => {
          if (
            plan.grade === this.currentSubscription?.plan.grade &&
            plan.duration === this.currentSubscription?.plan.duration
          ) {
            return this.currentSubscription.plan;
          }
          return plan;
        });
    } catch (e: unknown) {
      // TODO process error
      console.error(e);
    } finally {
      this.state = 'done';
    }
  }

  *subscribe(paymentMethod: string, saveCard: boolean) {
    const dto: SubscribeRequest = {
      planId: (this.selectedPlan?.basePlan?.id || this.selectedPlan?.id) ?? '',
      paymentMethod,
      paymentStrategy: 'stripe',
      extendedCredits: this.getSliderValueToSubscribe(),
      saveCard,
    };

    const secret: ClientSecret =
      this.selectedPlan && this.checkSelectedPlanIsSubscribed(this.selectedPlan)
        ? yield subscriptionService.changePlan(dto)
        : yield subscriptionService.subscribe(dto);

    return secret;
  }

  *cancel() {
    yield subscriptionService.cancel();
  }
}

const subscriptionStore = new SubscriptionStore();

const Context = createContext(subscriptionStore);

const useSubscriptionStore = () => useContext(Context);

export { subscriptionStore, useSubscriptionStore };

export default SubscriptionStore;
