export const getDateName = (dateString: string, uppercase: boolean) => {
  if (!dateString) return '';
  const date = new Date(dateString);

  const monthNames = new Intl.DateTimeFormat('eng', { month: 'long' }).format;

  const name = uppercase
    ? monthNames(date)[0].toLocaleUpperCase() + monthNames(date).slice(1)
    : monthNames(date);
  const dateText = date.getDate() + ' ' + name + ', ' + date.getFullYear();

  return dateText;
};
