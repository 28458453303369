import { AxiosInstance } from 'axios';

import withAuthorization from '../../withAuth';
import { ICardInfo } from './card-info.interface';

class CardInfoService {
  private requestInstance: AxiosInstance;

  constructor(requestInstance: AxiosInstance) {
    this.requestInstance = requestInstance;
  }

  async getCard(): Promise<ICardInfo | null> {
    const { data } = await this.requestInstance.get<ICardInfo | null>('paymentMethod');
    return data;
  }
  async deleteCard(): Promise<void> {
    await this.requestInstance.delete<void>('paymentMethod');
  }
}

export const cardInfoService = new CardInfoService(withAuthorization);
