export const getValidLink = (url: string) => {
  const validLinkPattern = /^(https?).+$/;
  return !validLinkPattern.test(url) ? 'https://' + url : url;
};

export const redirectToUrlByClick = (url: string) => {
  const validLinkPattern = /^(https?).+$/;
  if (!validLinkPattern.test(url)) url = 'https://' + url;

  window.open(url, '_blank');
};
