import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { useEffect } from 'react';

export const AuthWrapper = ({ children }: { children: unknown }) => {
  const userProfile = useUserProfile();

  useEffect(() => {
    userProfile.getUserProfileData();
  }, [userProfile]);

  return <>{children}</>;
};
