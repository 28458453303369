import { Stack, Typography } from '@mui/material';

//import LogoComponent from '../../../shared/assets/images/Logo.png'
import { ReactComponent as LogoComponent } from '../../../shared/assets/icons/Logo.svg';
import BackgroundImage from '../../assets/images/admin-auth-bg.jpg';
import BackgroundImageCompressed from '../../assets/images/admin-auth-bg-compressed.jpg';
import { ProgressiveImage } from '../progressive-image';
import c from './style.module.scss';

const AuthRightSideBar = () => {
  return (
    <Stack height="100%" position="relative" justifyContent="flex-end">
      <ProgressiveImage
        src={BackgroundImage}
        placeholderSrc={BackgroundImageCompressed}
        alt="backgroundImage"
        className={c.bg}
      />
      <Stack gap={8} pb={64} pl={88} pr={10}>
        <Typography
          variant="h2"
          fontSize={(theme) => theme.spacing(24)}
          lineHeight={(theme) => theme.spacing(48)}
          className={c.text}
        >
          <LogoComponent />
        </Typography>
        <Typography variant="h2" className={c.text}>
          Find and contact every potential customer in the world
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AuthRightSideBar;
