import { Stack } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { useAuthenticated } from 'ra-core';
import { ReactNode, useEffect } from 'react';
import { Link } from 'react-admin';
import { Outlet } from 'react-router-dom';

import { AvatarButton } from '../../features/user';
import { ReactComponent as LogoComponent } from '../../shared/assets/icons/LogoSmall.svg';

const UserAccountLayout = ({ children }: { children?: ReactNode }) => {
  useAuthenticated();

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <Stack minHeight="100vh">
      <Stack
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: 1000,
          backgroundColor: (theme) => theme.palette.primary.contrastText,
          boxShadow: `0 4px 4px 0 rgba(111, 113, 122, 0.2)`,
        }}
      >
        <Stack
          px={24}
          pt={24}
          pb={15}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Link to={AppRoutes.Contacts}>
            <LogoComponent />
          </Link>
          <AvatarButton />
        </Stack>
      </Stack>
      <Stack flexGrow={1} mt={75}>
        {children ?? <Outlet />}
      </Stack>
    </Stack>
  );
};

export default UserAccountLayout;
