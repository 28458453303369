import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { IExportedContactsResult } from '@shared/api/services/contact-service';
import { useEffect, useState } from 'react';

//import { ReactComponent as SubIcon } from '../../../shared/assets/icons/iconSubscription.svg';

interface IExportedContactsPopupProps {
  exportedContacts: IExportedContactsResult | null;
}
const ExportedContactsPopup = ({ exportedContacts }: IExportedContactsPopupProps) => {
  const [show, setShow] = useState(false);
  const [contacts, setContacts] = useState<IExportedContactsResult | null>(null);

  useEffect(() => {
    if (exportedContacts) {
      setShow(true);
      setContacts(exportedContacts);
    }
  }, [exportedContacts]);

  const handleClose = () => {
    setShow(false);
    setContacts(null);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Stack
          alignItems="center"
          justifyContent="center"
          p={24}
          pt={48}
          sx={{
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(197,190,249,0.6) 78%)',
            position: 'relative',
          }}
          minWidth={380}
        >
          {/*<Stack justifyContent="center" alignItems="center">*/}
          {/*  <Stack p={18} sx={{ backgroundColor: '#C5BEF9' }} borderRadius="50%" mb={32}>*/}
          {/*    <SubIcon />*/}
          {/*  </Stack>*/}
          {/*</Stack>*/}
          <Stack gap={8} mb={48} alignItems="center" justifyContent="center">
            <Typography variant="h5" color="text.primary">
              Export Report
            </Typography>
            <Typography variant="subtitle2" color="text.primary" maxWidth={290} textAlign="center">
              {contacts?.successfull} of {contacts?.all} contacts was exported successfully.
            </Typography>
            {!!contacts?.unsuccessful?.length && (
              <Typography
                variant="subtitle2"
                color="text.primary"
                maxWidth={290}
                textAlign="center"
              >
                Reasons:
                {Array.from(new Set(contacts?.unsuccessful)).map((reason, i) => (
                  <Typography
                    key={i}
                    variant="subtitle2"
                    color="text.primary"
                    maxWidth={290}
                    textAlign="center"
                  >
                    {reason}
                  </Typography>
                ))}
              </Typography>
            )}
          </Stack>
          <Stack gap={16} justifyContent="stretch" width="100%">
            <Button variant="contained" onClick={() => handleClose()}>
              Understand
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ExportedContactsPopup;
