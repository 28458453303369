import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';

interface CardButtonProps {
  buttonText: string;
  onClick: () => void;
}

const CardButton: React.FC<CardButtonProps> = ({ buttonText, onClick }) => {
  return (
    <Box display="flex" justifyContent="center" marginTop={9}>
      <Button
        variant="text"
        sx={{
          borderRadius: (theme) => theme.spacing(8),
          backgroundColor: (theme) => theme.palette.secondary.main,
        }}
        onClick={onClick}
      >
        <Typography variant="subtitle2" color="primary.main" fontWeight={600}>
          {buttonText}
        </Typography>
      </Button>
    </Box>
  );
};

export default CardButton;
