import { Skeleton, Stack } from '@mui/material';

const ContactsSkeleton = () => {
  return (
    <Stack
      flexWrap="wrap"
      width="100%"
      height="100%"
      direction="row"
      spacing={6}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        '& .MuiSkeleton-root:first-of-type': {
          ml: 6,
        },
      }}
    >
      {new Array(65).fill(null).map((_, i) => (
        <Skeleton
          component="div"
          sx={{
            flex: '0 0 16.666%',
          }}
          key={i}
        />
      ))}
    </Stack>
  );
};

export default ContactsSkeleton;
