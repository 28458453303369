import { ISimpleCompany } from '@shared/api/services/company-service';

export function combineLocation(company: ISimpleCompany): string {
  const locationArr: string[] = [
    company.city ?? '',
    company.region ?? '',
    company.country_code ?? '',
  ].filter((el) => el);

  return locationArr.join(', ') ?? '';
}
