import { Box, Button, Stack, Typography } from '@mui/material';
import { IPlan, PlanStatus } from '@shared/api/services/plan-service/plan.interfaces';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { SubscriptionCard } from './interfaces';
import { useSubscriptionStore } from './model/subscription.model';
import CreditList from './UI/credit-list';

const Subscription: FC<SubscriptionCard> = ({ plan, setScrollToCustom }) => {
  const subscription = useSubscriptionStore();

  const isSelectedPlan = subscription.getIsSelectedPlan(plan);
  const isFree = plan.grade === 'standard' || plan.status === PlanStatus.CANCELED;

  const planType = isFree ? 'Basic' : plan.name;
  const priceSubtitle = isFree ? 'Free plan for all users.' : 'Ideal for individual sales.';
  const price = isFree ? (
    'Free'
  ) : (
    <>
      ${plan.price}
      <Typography component="span" fontWeight={700}>
        /{plan.duration}
      </Typography>
    </>
  );

  const isSubscribed = subscription.checkSelectedPlanIsSubscribed(plan);

  const getButtonText = (isFree: boolean, isSubscribed: boolean, plan: IPlan) => {
    if (!subscription.currentSubscription && plan.id === subscription.freePlan?.id) {
      return 'Current plan';
    }

    if (isFree) {
      return isSubscribed ? 'Current plan' : `Get ${plan.name} plan`;
    }

    return isSubscribed ? 'Сustomize' : `Get ${plan.name} plan`;
  };

  const btnText = getButtonText(isFree, subscription.checkSelectedPlanIsSubscribed(plan), plan);

  const onCardClick = () => {
    if (plan.grade !== 'standard') {
      setScrollToCustom(true);
    }
    subscription.setSelectedPlan(plan);
  };

  return (
    <Stack
      px={32}
      pt={32}
      pb={24}
      flex="0 1 384px"
      onClick={onCardClick}
      sx={{
        borderRadius: (theme) => theme.spacing(12),
        borderWidth: 1,
        borderColor: isSelectedPlan ? 'primary.main' : 'customGray1.light',
        borderStyle: 'solid',
        cursor: 'pointer',
        transition: 'border-color 200ms ease',
        '&:hover': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Typography variant="subtitle1" mb={24}>
        {planType}
      </Typography>
      <Stack mb={24}>
        <Typography variant="h2">{price}</Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {priceSubtitle}
        </Typography>
      </Stack>
      <Stack>
        <CreditList
          email={plan.emailCreditsAmount}
          duration={plan.duration}
          //exportC={plan.exportCreditsAmount}
          phone={plan.phoneCreditsAmount}
        />
      </Stack>
      <Box width="100%" height="1px" bgcolor="customGray1.light" mt={32} mb={24} />
      <Button
        variant="contained"
        sx={{
          px: 23,
          py: 12,
        }}
        disabled={
          (!subscription.currentSubscription && plan.id === subscription.freePlan?.id) ||
          (isSubscribed && isFree)
        }
      >
        {btnText}
      </Button>
    </Stack>
  );
};

export default observer(Subscription);
