import { Box, Stack, Typography } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { useSubscriptionStore } from '@/entities/subscription';
import stripePromise from '@/shared/api/stripePromise';
import usePreventRefreshing from '@/shared/hooks/usePreventRefreshing';

import { StripeForm } from '../../../features/subscription';
import { ReactComponent as ArrowIcon } from '../../../shared/assets/icons/arrowLeft.svg';

const StripePromise = stripePromise;

const PaymentPage = () => {
  const subscription = useSubscriptionStore();
  const options: StripeElementsOptions = {
    mode: subscription.paymentMode,
    amount: Number(subscription.totalExtendedPrice),
    currency: subscription.currency,
    paymentMethodCreation: 'manual',
    payment_method_types: ['card'],
  };
  const refIsShouldStopUpdating = useRef(false);
  usePreventRefreshing(refIsShouldStopUpdating);
  return (
    <>
      <Box m="0 auto" maxWidth={1600} width="100%" px={20}>
        <Box mt={10} mb={16} sx={{ '& a': { textDecoration: 'none' } }}>
          <Link to={`${AppRoutes.ProfileSubscription}`}>
            <Stack direction="row" alignItems="center" gap={7}>
              <Stack alignItems="center" justifyContent="center">
                <ArrowIcon />
              </Stack>
              <Typography variant="subtitle1" color="customGray2.dark">
                Back to Plan Selection
              </Typography>
            </Stack>
          </Link>
        </Box>
      </Box>
      <Stack
        direction="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        gap={24}
        marginBottom="1.75rem"
      >
        {' '}
        <Elements stripe={StripePromise} options={options}>
          <StripeForm refIsShouldStopUpdating={refIsShouldStopUpdating} />
        </Elements>
      </Stack>
    </>
  );
};

export default observer(PaymentPage);
