import { Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowIcon } from '@shared/assets/icons/arrowLeft.svg';
import { AppRoutes } from '@shared/interfaces';
import { Link } from 'react-router-dom';

export function BackToPeople() {
  return (
    <Link to={`${AppRoutes.Contacts}`} style={{ textDecoration: 'none' }}>
      <Stack direction="row" alignItems="center" gap={7}>
        <Stack alignItems="center" justifyContent="center">
          <ArrowIcon />
        </Stack>
        <Typography variant="subtitle1" color="customGray2.dark">
          Back to people
        </Typography>
      </Stack>
    </Link>
  );
}
