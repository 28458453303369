import axios, { AxiosInstance } from 'axios';

import apiURL from '../../baseURL';
import TokenService from '../../tokenService';
import { IUser } from '../auth-service';

const profileInstance = axios.create({
  baseURL: apiURL + 'profile',
});

profileInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      throw new axios.Cancel('Not login');
    }
    return config;
  },
  (error) => Promise.reject(error)
);
class ProfileService {
  private requestInstance: AxiosInstance;

  constructor(requestInstance: AxiosInstance) {
    this.requestInstance = requestInstance;
  }

  async getProfile(): Promise<IUser> {
    const { data } = await this.requestInstance.get<IUser>('');

    return data;
  }
}

export const profileService = new ProfileService(profileInstance);
