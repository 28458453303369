import { Box, capitalize, Stack } from '@mui/material';

export function ChangeTable({ value, arr }: { value: string; arr: [string, () => void][] }) {
  const selectedItem = arr.findIndex((el) => el[0] === value);
  return (
    <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
      <Stack
        position="relative"
        direction="row"
        component={'div'}
        py={4}
        px={8}
        display="flex"
        gap={16}
        width={'fit-content'}
      >
        {arr.map((el, i) => (
          <Box
            key={el[0]}
            onClick={el[1]}
            component="div"
            zIndex={100}
            color="primary.main"
            fontWeight={selectedItem === i ? 'bold' : 'normal'}
            fontSize={16}
            borderBottom={selectedItem === i ? '2px #3626A7 solid' : ''}
            textAlign="center"
            sx={{ lineHeight: '32px', cursor: 'pointer', opacity: selectedItem === i ? 1 : 0.85 }}
          >
            {capitalize(el[0])}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}
