import { useCompanyFilter } from '@features/companies/filters/hooks/filter-context';
import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import { ICompanyLocationListIds } from '@shared/api/services/company-service';
import { makeStringFromObjectLocationCity } from '@shared/libs/locations/makeStringFromObjectLocationCity';
import { makeStringFromObjectLocationCountry } from '@shared/libs/locations/makeStringFromObjectLocationCountry';
import { makeStringFromObjectLocationRegion } from '@shared/libs/locations/makeStringFromObjectLocationRegion';
import { useMemo, useState } from 'react';
import { useListController } from 'react-admin';

import { ReactComponent as BagIcon } from '../../../shared/assets/icons/bag.svg';
import { ReactComponent as ManIcon } from '../../../shared/assets/icons/black-man.svg';
import { ReactComponent as IndustryIcon } from '../../../shared/assets/icons/industry-building.svg';
import { ReactComponent as LocationIcon } from '../../../shared/assets/icons/location-marker.svg';
import CountryCodeFilter from './filter-items/country-code-filter';
import FilterItem from './filter-items/filter-item';
import FullNameFilter from './filter-items/full-name-filter';
import PrimaryRoleInput from './filter-items/primary-role-input';
import TypeFilter from './filter-items/type-filter';
import { CompanyFilterItemProps } from './interfaces';

const CompanyFilters = () => {
  const { isFetching } = useListController();

  const [locationList, setLocationList] = useState<ICompanyLocationListIds>();

  const processedLocationList = useMemo(() => {
    const values: { id: string; value: string }[] = [];
    locationList?.cities.forEach((el) => {
      const string = makeStringFromObjectLocationCity(el);
      values.push({ id: el.id, value: string.slice(0, -2) });
    });
    locationList?.regions.forEach((el) => {
      const string = makeStringFromObjectLocationRegion(el);
      values.push({ id: el.id, value: string.slice(0, -2) });
    });
    locationList?.countries.forEach((el) => {
      const string = makeStringFromObjectLocationCountry(el);
      values.push({ id: el.id, value: string.slice(0, -2) });
    });
    return values;
  }, [locationList]);

  const fields: CompanyFilterItemProps[] = [
    { title: 'Name', icon: ManIcon, Filter: FullNameFilter },
    { title: 'Type', icon: BagIcon, Filter: TypeFilter },
    { title: 'Primary Role', icon: LocationIcon, Filter: PrimaryRoleInput },
    {
      title: 'Country Code',
      icon: IndustryIcon,
      Filter: CountryCodeFilter,
      locationList,
      processedLocationList,
      setLocationList,
    },
  ];

  return (
    <Stack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      height="100%"
    >
      <Box flexGrow={1}>
        <FilterHeader />
        <Stack
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            borderTop: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            '& .contact-filter-item:not(:last-child)': {
              borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            },
          }}
          px={24}
        >
          {fields.map((filter) => (
            <FilterItem {...filter} key={filter.title} />
          ))}
        </Stack>
      </Box>
      <Stack px={20} pb={32}>
        <ApplyButton type="submit" disabled={isFetching} />
      </Stack>
    </Stack>
  );
};

function FilterHeader() {
  const { activeFiltersQuantity, resetAll } = useCompanyFilter();
  return (
    <Stack px={24} mb={16} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={4}>
        <Typography variant="h5">Filters</Typography>
        {!!activeFiltersQuantity && (
          <Box
            px={12}
            borderRadius={30}
            height={20}
            sx={{
              bgcolor: 'customGray2.main',
            }}
          >
            <Typography variant="body2">{activeFiltersQuantity}</Typography>
          </Box>
        )}
      </Stack>
      {!!activeFiltersQuantity && <Button onClick={resetAll}>Clear filter</Button>}
    </Stack>
  );
}

function ApplyButton({ disabled, ...props }: ButtonProps) {
  const { applyFilter, isApplyButtonDisabled } = useCompanyFilter();
  return (
    <Button
      variant="contained"
      disabled={isApplyButtonDisabled || disabled}
      {...props}
      onClick={() => {
        applyFilter?.();
      }}
    >
      Apply filters
    </Button>
  );
}

export default CompanyFilters;
