import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface IIntegrationModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  image: string;
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: 30,
    width: 650,
    maxWidth: 650,
  },
});

const IntegrationModal = ({ open, setOpen, name, image }: IIntegrationModal) => {
  const classes = useStyles();

  const openIntegration = () => {
    if (name.toLowerCase() === 'salesforce') {
      localStorage.setItem('integration', 'salesforce');
      const url = new URL('https:/login.salesforce.com/services/oauth2/authorize');
      url.searchParams.append('client_id', process.env.REACT_APP_SALESFORCE_CLIENT_ID ?? '');
      url.searchParams.append(
        'client_secret',
        process.env.REACT_APP_SALESFORCE_CLIENT_SECRET ?? ''
      );
      url.searchParams.append(
        'response_type',
        process.env.REACT_APP_SALESFORCE_RESPONSE_TYPE ?? ''
      );
      url.searchParams.append('redirect_uri', process.env.REACT_APP_SALESFORCE_REDIRECT_URI ?? '');
      url.searchParams.append('scope', process.env.REACT_APP_SALESFORCE_SCOPE ?? '');
      window.open(url, '_self');
    } else if (name.toLowerCase() === 'hubspot') {
      localStorage.setItem('integration', 'hubspot');
      const url = new URL('https://app.hubspot.com/oauth/authorize');
      url.searchParams.append('client_id', process.env.REACT_APP_HUBSPOT_CLIENT_ID ?? '');
      url.searchParams.append('redirect_uri', process.env.REACT_APP_HUBSPOT_RETURN_URI ?? '');
      url.searchParams.append('scope', process.env.REACT_APP_HUBSPOT_SCOPE ?? '');
      window.open(url, '_self');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={64}
        sx={{
          borderBottom: ' 1px solid #dfdfdf',
          fontWeight: 'bold',
        }}
      >
        <Typography fontSize={20} fontWeight={700} color="black">
          Connect an account
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px 100px 0px !important',
        }}
      >
        <Box component="img" src={image} sx={{ maxWidth: '70px' }}></Box>
        <Typography
          fontSize={24}
          variant="h6"
          m={'14px 45px'}
          textAlign={'center'}
          color="black"
          sx={{ fontWeight: 'bold' }}
        >
          Allow Bruxt to access your {name} account?
        </Typography>
        <Typography variant="body2" component="div" textAlign={'center'} mb={40}>
          Link {name} to automatically dedupe against your existing contacts/leads, bidirectionally
          sync activities, and enrich your {name} records.
        </Typography>
        <Typography variant="body2" component="div" textAlign={'center'} mb={40}>
          By clicking “Yes, Continue” below, you acknowledge that business contact data submitted
          from your Salesforce account to Bruxt may be used to provide and improve Bruxt&apos;s
          services as further described in our <a href="about:blank">Terms of Service</a>.<br />
          <a href="about:blank">Learn more</a> about data sharing.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '60px' }}>
        <Button
          variant="contained"
          sx={{ fontSize: 16, lineHeight: 1, height: 'fit-content', width: 'fit-content' }}
          onClick={openIntegration}
        >
          Yes, continue
        </Button>
        <Button
          variant="outlined"
          sx={{ fontSize: 16, lineHeight: 1, height: 'fit-content', width: 'fit-content' }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IntegrationModal;
