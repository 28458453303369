import { Button, Stack } from '@mui/material';
import { authService } from '@shared/api/services/auth-service';
import { AppRoutes } from '@shared/interfaces';
import { ChangeEvent, useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { AuthTitle } from '../../../shared/ui/auth';
import { BackButton } from '../../../shared/ui/buttons';
import { PasswordField } from '../../../shared/ui/inputs';
import { fields } from './reset-password.meta';

const ResetPasswordPage = () => {
  const [passwords, setPasswords] = useState({
    password: '',
    replyPassword: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const notify = useNotify();

  const navigate = useNavigate();

  const onFieldChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  const onResetPasswordHandler = async () => {
    const { password, replyPassword } = passwords;
    if (password !== replyPassword || !password) {
      notify('Passwords must math', { type: 'error' });
      return;
    }
    const params = new URL(window.location.href).searchParams;
    const code = params.get('code')!;
    setIsLoading(true);
    try {
      const email = await authService.getEmailByCode(code!);
      const { message } = await authService.forgotResetPassword({
        email,
        code,
        password,
      });
      notify(message, { type: 'success' });
      navigate(AppRoutes.Login, { replace: true });
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e?.response?.data?.message || 'Something went wrong, try later', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack direction="row" mb={20}>
        <BackButton />
      </Stack>
      <AuthTitle
        title="Create new password"
        subTitle="Your new password must be different from previous used passwords."
      />
      <Stack mb={40} gap={24}>
        {fields.map(({ name, placeholder, title }) => (
          <PasswordField
            name={name}
            key={name}
            placeholder={placeholder}
            title={title}
            value={passwords[name as 'password']}
            onChange={onFieldChangeHandler}
          />
        ))}
      </Stack>
      <Button variant="contained" onClick={onResetPasswordHandler} disabled={isLoading}>
        Reset password
      </Button>
    </Stack>
  );
};

export default ResetPasswordPage;
