import { Stack, Tooltip } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as CopyIcon } from '../../assets/icons/iconCopy.svg';
import { copyInBuffer } from '../../libs/validations';

const CopyIconButton: FC<{ value?: string; title: string; onClick: () => void }> = ({
  value,
  onClick,
  title,
}) => {
  const onClickHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    if (!value) {
      onClick();
      return;
    }
    copyInBuffer(value);
  };

  return (
    <Stack
      direction="row"
      sx={{
        '& .copy': {
          cursor: 'pointer',
        },
        '& .copy path': {
          transition: 'fill ease-out 300ms',
        },
        '& .copy:hover path': {
          fill: (theme) => theme.palette.primary.main,
        },
      }}
    >
      <Tooltip title={title}>
        <CopyIcon className="copy" onClick={(e) => onClickHandler(e)} />
      </Tooltip>
    </Stack>
  );
};

export default CopyIconButton;
