import { InDevelopment } from '@features/common/layouts/in-development';
import { ChangeTable } from '@features/common/switchers/changeTable';
import { Box, Divider, Stack } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { IDataLayout } from '@widgets/data-layouts/types';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { DataPagination } from '../../features/contacts';
import { AvatarButton } from '../../features/user';
import { ReactComponent as LogoComponent } from '../../shared/assets/icons/LogoSmall.svg';
import { FirstEnter } from './first-enter/first-enter';
import useIsNoFilters from './hooks/useIsNoFilters';
import c from './style.module.scss';

const DataLayout = observer(({ children, header, filter, show, type }: IDataLayout) => {
  const { isFilterApplied } = useIsNoFilters();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const contentSlot =
    type !== 'companies' && type !== 'contacts' ? (
      <InDevelopment />
    ) : !show && !isFilterApplied ? (
      <FirstEnter />
    ) : (
      <>
        {children}
        <Stack direction="row" alignItems="center">
          <DataPagination />
        </Stack>
      </>
    );

  return (
    <Box minHeight="100vh" width="100%" className={c.wrapper}>
      <Stack
        flex="0 0 300px"
        width={300}
        sx={{
          borderRight: (theme) => `1px solid ${theme.palette.customGray1.light}`,
        }}
        className={c.filters}
      >
        <Box pt={24} pl={24} pb={24}>
          <Link to={AppRoutes.Contacts}>
            <LogoComponent />
          </Link>
        </Box>
        {filter}
      </Stack>
      {/* header */}
      <Stack
        flex="0 0 75px"
        pb={16}
        pt={24}
        px={24}
        className={c.header}
        bgcolor="primary.contrastText"
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
        }}
        direction="row"
      >
        <Stack
          direction="column"
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: '#dbdbdb', marginTop: '1px' }}
            />
          }
          spacing={20}
          alignItems="flex-start"
        >
          <ChangeTable
            value={type}
            arr={[
              ['companies', () => navigate(AppRoutes.Companies)],
              ['contacts', () => navigate(AppRoutes.Contacts)],
              ['emails', () => navigate(AppRoutes.Emails)],
              ['lists', () => navigate(AppRoutes.Lists)],
            ]}
          />
          {header}
        </Stack>

        <Box flexGrow={1} />
        <AvatarButton />
      </Stack>
      <Stack className={c.children} px={24} pt={24} pb={10}>
        {contentSlot}
      </Stack>
    </Box>
  );
});

export default DataLayout;
