import { AxiosInstance } from 'axios';

import withAuthorization from '../../withAuth';
import { IIntegration, IIntegrationRequest, IIntegrationResponse } from './integration.interfaces';

class IntegrationService {
  private requestInstance: AxiosInstance;

  constructor(requestInstance: AxiosInstance) {
    this.requestInstance = requestInstance;
  }

  async getIntegrationStatus(): Promise<IIntegration> {
    const { data } = await this.requestInstance.get<IIntegration>('integration/crm/status');

    return data;
  }

  async loginCRM<T extends 'salesforce' | 'hubspot'>(type: T, code: string): Promise<string> {
    const { data } = await this.requestInstance.get<string>(
      `integration/crm/${type}/oauth-callback?code=${code}`
    );

    return data;
  }

  async logoutCRM<T extends 'salesforce' | 'hubspot'>(type: T): Promise<boolean> {
    const { data } = await this.requestInstance.delete<boolean>(`integration/crm/${type}/logout`);

    return data;
  }

  async importCRM<T extends 'salesforce' | 'hubspot'>(
    type: T,
    body: IIntegrationRequest
  ): Promise<IIntegrationResponse> {
    const { data } = await this.requestInstance.post<IIntegrationResponse>(
      `integration/crm/${type}/import`,
      body
    );

    return data;
  }
}

export const integrationService = new IntegrationService(withAuthorization);
