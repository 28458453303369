import { AppRoutes } from '@shared/interfaces';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { flowResult } from 'mobx';
import { useNotify } from 'ra-core';
import { useNavigate } from 'react-router-dom';

import SubscriptionStore from '../../../entities/subscription/model/subscription.model';
import UserProfileStore from '../../../pages/user-profile/model/user-profile.store';
const checkSubscriptionStatus = async (
  stripe: Stripe | null,
  clientSecret: string,
  userStore: UserProfileStore,
  subscription: SubscriptionStore,
  navigate: ReturnType<typeof useNavigate>,
  notify: ReturnType<typeof useNotify>,
  setIsProgress: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!stripe) return;

  const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

  if (paymentIntent) {
    switch (paymentIntent.status) {
      case 'succeeded':
        notify('Check your email for the invoice');
        userStore.updateUserProfileData();
        navigate(AppRoutes.Profile);
        setIsProgress(false);
        break;
      case 'requires_payment_method':
        notify('requires_payment_method error', { type: 'error' });
        break;
      default:
        notify('Payment has not passed. Try again later', { type: 'error' });
        navigate(AppRoutes.Profile);
        setIsProgress(false);
    }
    return;
  }
  checkSubscriptionStatus(
    stripe,
    clientSecret,
    userStore,
    subscription,
    navigate,
    notify,
    setIsProgress
  );
};

const redirectAfterPayment = (refIsShouldBlockPageRefreshing: React.MutableRefObject<boolean>) => {
  refIsShouldBlockPageRefreshing.current = false;
  // eslint-disable-next-line no-restricted-globals
  return location.origin + AppRoutes.Profile;
};

const handlePayment = async (
  stripe: Stripe | null,
  elements: StripeElements | null,
  paymentMethod: string,
  saveCard: boolean,
  subscription: SubscriptionStore,
  userStore: UserProfileStore,
  navigate: ReturnType<typeof useNavigate>,
  notify: ReturnType<typeof useNotify>,
  setIsProgress: React.Dispatch<React.SetStateAction<boolean>>,
  refIsShouldBlockPageRefreshing: React.MutableRefObject<boolean>
) => {
  if (!stripe) return;
  if (subscription.selectedPlan?.id === subscription.freePlan?.id) {
    return;
  }
  const clientSecret = await flowResult(subscription.subscribe(paymentMethod, saveCard));
  if (Object.keys(clientSecret).length === 0) {
    userStore.updateUserProfileData();
    navigate(AppRoutes.Profile);
    setIsProgress(false);
  } else {
    const confirmPayment = await stripe.confirmPayment({
      elements: elements ?? undefined,
      clientSecret: clientSecret.clientSecret,
      confirmParams: {
        return_url: redirectAfterPayment(refIsShouldBlockPageRefreshing),
      },
    });
    if (confirmPayment.error) {
      notify(confirmPayment.error.message, { type: 'error' });
    } else {
      checkSubscriptionStatus(
        stripe,
        clientSecret.clientSecret,
        userStore,
        subscription,
        navigate,
        notify,
        setIsProgress
      );
    }
  }
};

export { checkSubscriptionStatus, handlePayment };
