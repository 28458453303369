export function sortExperiences<T extends { end_date: string; is_primary?: boolean }>(
  experiences: T[]
) {
  const current = experiences.length
    ? 'is_primary' in experiences[0]
      ? experiences.find((value) => value.is_primary)
      : experiences.find((value) => !value.end_date)
    : null;

  experiences = experiences
    .filter((exp) => ('is_primary' in exp ? !exp.is_primary : exp.end_date))
    .sort((a, b) => {
      const endDateA = a.end_date
        ? a.end_date.length === 4
          ? a.end_date
          : new Date(a.end_date).getFullYear()
        : '';

      const endDateB = b.end_date
        ? b.end_date.length === 4
          ? b.end_date
          : new Date(b.end_date).getFullYear()
        : '';

      if (endDateA < endDateB) {
        return 1;
      } else if (endDateA > endDateB) {
        return -1;
      } else {
        return 0;
      }
    });

  return current ? [current, ...experiences] : experiences;
}
