import { Box, Button, Stack, Typography } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { AppRoutes } from '@shared/interfaces';
import { isEmailValid } from '@shared/libs/validations';
import { ChangeEvent, useState } from 'react';
import { Link, useLogin, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { useContactsStore } from '../../../entities/contact-table';
import landingURL from '../../../shared/api/landingURL';
import { AuthTitle } from '../../../shared/ui/auth';
import { BackButton } from '../../../shared/ui/buttons';
import { InputField, PasswordField } from '../../../shared/ui/inputs';

const LoginForm = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');

  const notify = useNotify();

  const navigate = useNavigate();

  const login = useLogin();

  const userStore = useUserProfile();
  const contactStore = useContactsStore();

  const onCredentialChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setError('');
  };

  const onBackClickHandler = () => {
    window.location.replace(landingURL || 'https://bruxt.com');
  };

  const onSubmitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email, password } = credentials;
    if (!isEmailValid(email)) {
      setError('Input a valid email');
      return;
    }
    try {
      setIsLoading(true);
      await login({
        email,
        password,
      });
      userStore.updateUserProfileData();
      contactStore.getSaved();
      navigate(AppRoutes.Contacts);
    } catch {
      notify('Invalid email or password', {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack component="form" onSubmit={onSubmitHandler}>
      <Stack direction="row" mb={20}>
        <BackButton onClick={onBackClickHandler} />
      </Stack>
      <AuthTitle
        title="Login"
        subTitle="Welcome back! Login to your account - find more potential customers."
      />
      <Box>
        <Stack mb={24}>
          <InputField
            title="Email"
            placeholder="Enter your email"
            name="email"
            value={credentials.email}
            onChange={onCredentialChangeHandler}
            required
            error={!!error}
            errorText={error}
          />
        </Stack>
        <Stack mb={8}>
          <PasswordField
            title="Password"
            placeholder="Enter your password"
            name="password"
            value={credentials.password}
            onChange={onCredentialChangeHandler}
            required
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Link to={AppRoutes.ForgotPassword}>
            <Typography variant="subtitle1" color="primary.main">
              Forgot password?
            </Typography>
          </Link>
        </Stack>
      </Box>
      <Stack mt={48} gap={32}>
        <Button variant="contained" type="submit" disabled={isLoading}>
          Login
        </Button>
      </Stack>
    </Stack>
  );
};

export default LoginForm;
