import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as Lock } from '../../../shared/assets/icons/Icon_Lock.svg';

const OverlapSlider: FC<{ visible: boolean }> = ({ visible }) => {
  if (!visible) return null;

  return (
    <Stack
      alignItems="center"
      position="absolute"
      top={-20}
      left={-32}
      right={-32}
      bottom={-20}
      gap={18}
      zIndex={1000}
      sx={{
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #FFFFFF 100%)',
        backdropFilter: 'blur(8px)',
        borderRadius: '0px 0px 12px 12px',
      }}
    >
      <Lock />
      <Stack gap={4}>
        <Typography maxWidth={440} variant="h3" fontWeight={700} textAlign="center">
          Select a paid plan to customize it
        </Typography>
        <Typography maxWidth={385} variant="h4" textAlign="center">
          Credits are available on paid plans only. Please select a new plan to customize it.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default OverlapSlider;
