import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { contactService, IEmail } from '@shared/api/services/contact-service';
import { CreditTypes, UpgradeTypes } from '@shared/interfaces';
import { copyInBuffer } from '@shared/libs/validations';
import { autorun } from 'mobx';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNotify } from 'react-admin';

import { eventEmmiter } from '@/app/App';

import { useContactsStore } from '../../../entities/contact-table';
import { ReactComponent as MailIcon } from '../../../shared/assets/icons/iconMail.svg';
import { ReactComponent as BlackMailIcon } from '../../../shared/assets/icons/iconMailBlack.svg';
import { ReactComponent as WhiteMailIcon } from '../../../shared/assets/icons/iconMailWhite.svg';
import { CopyIconButton } from '../../../shared/ui/buttons';
import SpinButton from './spin-button';

export const EmailButton: FC<{
  contactId: string;
  isTable?: boolean;
  setEmailExists?: Dispatch<SetStateAction<boolean>>;
  isPhoneExists?: boolean;
  openedEmails?: IEmail[];
  isEmailOpened?: boolean;
}> = ({
  contactId,
  isTable = true,
  setEmailExists,
  isPhoneExists = false,
  openedEmails,
  isEmailOpened,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const userProfile = useUserProfile();
  const contactStore = useContactsStore();
  const [email, setEmail] = useState<null | IEmail[]>(openedEmails?.length ? openedEmails : null);

  const emailRef = useRef<null | HTMLParagraphElement>(null);

  const notify = useNotify();
  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const dispose = autorun(() => {
      const item = contactStore.getSavedById(contactId);

      if (item?.emails) {
        setEmail(item.emails);
        if (setEmailExists) setEmailExists(true);
      }
    });

    return () => dispose();
  }, [contactId, contactStore, setEmailExists]);

  const onSaveEmailHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsLoading(true);
    try {
      const emailResponse = await contactService.saveEmail(contactId);
      const contact = contactStore.getContactById(contactId)!;
      if (!emailResponse.length) {
        setEmail([{ type: '', address: '' }]);

        contactStore.addSavedContact({ ...contact, emails: [{ type: '', address: '' }] });

        if (setEmailExists) {
          setEmailExists(true);
        }
        return;
      }
      setEmail(emailResponse);

      contactStore.addSavedContact({ ...contact, emails: emailResponse });
      userProfile.incerementCreditsUsage(1, CreditTypes.EMAIL);

      if (setEmailExists) {
        setEmailExists(true);
      }
    } catch (e: unknown) {
      userProfile.toggleUpgradePopup(UpgradeTypes.EMAIL, CreditTypes.EMAIL);
      eventEmmiter.emmit('toggle-popup');
    } finally {
      setIsLoading(false);
    }
  };

  const onCopyEmail = (value: string) => {
    const isCopied = copyInBuffer(value);
    if (!isCopied) return;
    notify('Email is copied', { type: 'success' });
  };

  const onEmailClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleStopPropagation(e);
    if (!emailRef.current) return;
    const range = document.createRange();
    range.selectNodeContents(emailRef.current);
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    selection.addRange(range);
  };

  if (!email && isTable) {
    return (
      <Tooltip title="Click to request email. It costs one credit.">
        <div>
          <SpinButton
            icon={MailIcon}
            isLoading={isLoading}
            onClick={onSaveEmailHandler}
            isExists={false}
          />
        </div>
      </Tooltip>
    );
  }

  if (isEmailOpened && email && !isTable) {
    return (
      <>
        {email.map((e, index) => (
          <Stack
            direction="row"
            alignItems="center"
            gap={8}
            px={16}
            key={index}
            sx={{
              '&:first-of-type': {
                pt: 16,
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={(theme) => {
                return {
                  width: 44,
                  height: 44,
                  backgroundColor: theme.palette.primary.contrastText,
                  border: `1px solid ${theme.palette.customGray1.light}`,
                  borderRadius: '50%',
                };
              }}
            >
              <BlackMailIcon />
            </Stack>
            {!e.address ? (
              <Stack direction="row" gap={8} alignItems="center">
                <Typography variant="subtitle2" color="text.primary" onClick={onEmailClickHandler}>
                  There is no email
                </Typography>
                <Tooltip title="We do not take credit for this operation.">
                  <InfoOutlinedIcon color="disabled" fontSize="small" />
                </Tooltip>
              </Stack>
            ) : (
              <Stack gap={2}>
                <EmailInProfile email={e.address} onCopyEmail={onCopyEmail} />
                <Stack direction="row" alignItems="center" gap={8}>
                  <Typography variant="subtitle2" color="customGray2.dark">
                    Business
                  </Typography>
                  <Box
                    sx={{
                      width: 3,
                      height: 3,
                      bgcolor: 'customGray2.dark',
                      borderRadius: '50%',
                    }}
                  />
                  <Typography variant="subtitle2" color="customGray2.dark">
                    Primary
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        ))}
      </>
    );
  }

  if (!email && !isTable) {
    return (
      <Box
        sx={() => {
          return isPhoneExists
            ? { paddingX: 16, width: '100%' }
            : {
                paddingX: 0,
                width: '100%',
              };
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<WhiteMailIcon />}
          onClick={onSaveEmailHandler}
          disabled={isLoading}
          sx={{ width: '100%' }}
        >
          Get access to email
        </Button>
      </Box>
    );
  }

  return (
    <Stack
      direction="row"
      display="inline-flex"
      alignItems="center"
      gap={8}
      p={10}
      sx={{
        borderColor: 'customGray1.light',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '8px',
      }}
      onClick={(e) => onEmailClickHandler(e)}
    >
      {email![0]?.address ? (
        <>
          <Typography
            variant="subtitle2"
            color="primary.light"
            ref={emailRef}
            sx={{ cursor: 'text' }}
          >
            {email![0]?.address || 'There is no email'}
          </Typography>

          <CopyIconButton
            title="Copy email"
            onClick={() => onCopyEmail(email![0]?.address || '')}
          />
        </>
      ) : (
        <Stack direction="row" gap={8} alignItems="center">
          <Typography variant="subtitle2" color="text.primary" onClick={onEmailClickHandler}>
            There is no email
          </Typography>
          <Tooltip title="We do not take credit for this operation.">
            <InfoOutlinedIcon color="disabled" fontSize="small" />
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

function EmailInProfile({
  email,
  onCopyEmail,
}: {
  email: string;
  onCopyEmail: (value: string) => void;
}) {
  const emailRef = useRef<null | HTMLParagraphElement>(null);

  const onEmailClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!emailRef.current) return;
    const range = document.createRange();
    range.selectNodeContents(emailRef.current);
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    selection.addRange(range);
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={8}
      flexWrap="nowrap"
      onClick={onEmailClickHandler}
    >
      {!email ? (
        <Stack direction="row" gap={8}>
          <Typography variant="subtitle2" color="text.primary" onClick={onEmailClickHandler}>
            There is no email
          </Typography>
          <Tooltip title="We do not take credit for this operation.">
            <InfoOutlinedIcon color="disabled" fontSize="small" />
          </Tooltip>
        </Stack>
      ) : (
        <>
          <Typography variant="subtitle2" color="primary.light" ref={emailRef}>
            {email}
          </Typography>
          <CopyIconButton title="Copy email" onClick={() => onCopyEmail(email || '')} />
        </>
      )}
    </Stack>
  );
}
