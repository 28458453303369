import { useContactFilter } from '@features/contacts/filters/hooks/filter-context';
import { Box, IconButton, SxProps, TextField, TextFieldProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as Close } from '../../assets/icons/close-filter-icon.svg';
import { ReactComponent as Search } from '../../assets/icons/search-filter.svg';

const FilterInput: FC<
  TextFieldProps & { onResetField?: () => void } & { filter: 'full_name' | 'job_title' }
> = ({ onResetField, filter, ...props }) => {
  const { setFilterInputs, filters } = useContactFilter();

  const inputSx: SxProps = {
    '& .MuiInputBase-root': {
      pr: 8,
      pl: 6,
    },
    '& .MuiInputBase-input': {
      width: '100%',
    },
    '& .MuiOutlinedInput-input': {
      p: 8,
      pl: filters?.[filter] ? 10 : 30,
    },
    width: '100%',
  };

  const resetFieldHandler = () => {
    setFilterInputs?.((prev) => ({
      ...prev,
      [filter]: null,
    }));
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {!filters?.[filter] && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '7%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Search />
        </Box>
      )}
      <TextField
        {...props}
        value={filters?.[filter] || ''}
        sx={inputSx}
        InputProps={{
          endAdornment: filters?.[filter] && (
            <IconButton
              onClick={() => resetFieldHandler?.()}
              sx={{
                p: 4,
              }}
            >
              <Close />
            </IconButton>
          ),
        }}
        onChange={(event) => {
          setFilterInputs?.(
            (prev) =>
              (prev = { ...prev, [filter]: !event.target.value ? null : event.target.value })
          );
        }}
        onReset={() => resetFieldHandler()}
      />
    </Box>
  );
};

export default observer(FilterInput);
