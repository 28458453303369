import { useSubscriptionStore } from '@entities/subscription';
import { Box, Slider, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { PlanStatus } from '@/shared/api/services/plan-service/plan.interfaces';

import { sliderPositions, subtitles } from './credit-slider.meta';
import OverlapSlider from './overlap-slider';
import { createMatchValues, createSliderValues } from './utils';

interface CreditSliderProps {
  type: 'phone' | 'email' | 'export';
  makeRequest?: () => void;
}

const CreditSlider: FC<CreditSliderProps> = ({ type, makeRequest }) => {
  const subscription = useSubscriptionStore();
  const [selectedPosition, setSelectedPosition] = useState(0);

  useLayoutEffect(() => {
    makeRequest && makeRequest();
    setSelectedPosition(getCurrentSilderValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription.selectedPlan]);
  const getSliderCreditsAmount = useCallback(() => {
    if (subscription.selectedPlan?.duration === 'year') {
      switch (type) {
        case 'email':
          return subscription.yearEmails;
        case 'phone':
          return subscription.yearPhones;
        default:
          return 0;
      }
    } else {
      switch (type) {
        case 'email':
          return subscription.monthEmails;
        case 'phone':
          return subscription.monthPhones;
        default:
          return 0;
      }
    }
    // eslint-disable-next-line
  }, [subscription.selectedPlan?.duration]);

  const sliderValues = useMemo(
    () =>
      createSliderValues(
        getSliderCreditsAmount(),
        sliderPositions,
        subscription.selectedPlan?.duration === 'year' ? 'year' : 'mo'!
      ),
    [getSliderCreditsAmount, subscription.selectedPlan?.duration]
  );

  const matchValues = createMatchValues(getSliderCreditsAmount(), sliderPositions);

  const getCurrentSilderValue = () => {
    if (
      !subscription.isSelectedPlanSubscribed ||
      !subscription.currentSubscription ||
      subscription.currentSubscription.status === PlanStatus.CANCELED
    ) {
      subscription.deleteSlidersValues();
    }

    if (
      subscription.isSelectedPlanSubscribed &&
      subscription.currentSubscription &&
      subscription.currentSubscription.status !== PlanStatus.CANCELED
    ) {
      const additionalCredits =
        subscription.currentSubscription?.plan?.[`${type}CreditsAmount`] ?? 0;
      let currentPosition = matchValues.indexOf(additionalCredits);

      if (currentPosition === -1) {
        currentPosition = matchValues.reduce((currentSliderValue, value) => {
          if (additionalCredits >= value) {
            currentSliderValue++;
          }
          return currentSliderValue;
        }, -1);
      }
      subscription.setSliderValue(type, currentPosition, matchValues[currentPosition]);
      subscription.setSubscribedPlanSilderValue(
        type,
        currentPosition,
        matchValues[currentPosition]
      );
      return currentPosition;
    }
    return 0;
  };

  const changeCurrentSilderValue = (value: number) => {
    subscription.setSliderValue(type, value, matchValues[value]);
    return value;
  };

  const getCurrentSelectedPlan = () => {
    if (subscription.isSelectedPlanSubscribed && subscription.currentSubscription) {
      if (subscription.currentSubscription.plan.basePlan) {
        return subscription.currentSubscription.plan.basePlan;
      }
      return subscription.currentSubscription.plan;
    }

    return subscription.selectedPlan;
  };

  const getSliderTitle = () => {
    return `${getCurrentSelectedPlan()?.[`${type}CreditsAmount`] || 0} (${
      subscription.selectedPlan?.name ?? 'Selected Plan'
    } plan) + ${
      subscription.sliderValues[type].count
    } credits${`/${subscription.selectedPlan?.duration}`}`;
  };

  const subtitle = subtitles[type];

  const sliderTitle = getSliderTitle();

  const sliderValueText = `$${subscription.entityExtendedPrices[type].price}/${subscription.selectedPlan?.duration}`;

  const onChangeSliderValue = (_: Event, value: number | number[]) => {
    if (Array.isArray(value)) return;
    subscription.setSliderValue(type, value, matchValues[value]);
    const position = changeCurrentSilderValue(value);
    setSelectedPosition(position);
    makeRequest && makeRequest();
  };

  const hideSlider = !subscription.selectedPlan || subscription.selectedPlan?.grade === 'standard';

  return (
    <Stack
      px={32}
      pt={24}
      pb={32}
      sx={(theme) => ({
        borderRadius: theme.spacing(12),
        border: `1px solid ${theme.palette.customGray1.light}`,
      })}
    >
      <Stack gap={4}>
        <Typography variant="h5">{subtitle}</Typography>
        <Typography variant="subtitle2">
          {subtitle} are shared across users. Select how many {subtitle.toLocaleLowerCase()} you
          would like for your team below.
        </Typography>
      </Stack>
      <Box height={(theme) => theme.spacing(1)} bgcolor="customGray1.light" my={24} />
      <Stack position="relative">
        <OverlapSlider visible={hideSlider} />
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h3" fontWeight={700}>
              {sliderTitle}
            </Typography>
            <Typography variant="h3" fontWeight={700}>
              {sliderValueText}
            </Typography>
          </Stack>
          <Stack>
            <Slider
              min={0}
              max={sliderPositions}
              value={selectedPosition}
              onChange={(event, value) => onChangeSliderValue(event, value)}
              step={1}
              marks={sliderValues}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default observer(CreditSlider);
