import { Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { ContactFilterItemProps } from '../interfaces';

const FilterItem: FC<ContactFilterItemProps> = ({ title, icon: Icon, Filter, ...rest }) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleFocusChange = () => {
    setIsInputFocused(!isInputFocused);
  };

  return (
    <Stack
      gap={8}
      py={16}
      sx={{
        '&:not(:last-child)': {
          borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
        },
      }}
    >
      <Stack
        direction="row"
        gap={8}
        alignItems="center"
        sx={[
          isInputFocused && {
            '& svg *': {
              fill: (theme) => theme.palette.primary.main,
            },
          },
        ]}
      >
        <Icon />
        <Typography
          variant="subtitle2"
          color={isInputFocused ? 'primary.main' : 'text.primary'}
          fontWeight={500}
        >
          {title}
        </Typography>
      </Stack>
      <Filter onBlur={handleFocusChange} onFocus={handleFocusChange} {...rest} />
    </Stack>
  );
};

export default FilterItem;
