import { Box, Stack, Typography } from '@mui/material';

import { ReactComponent as ArrowIcon } from '../../../shared/assets/icons/arrowLeftFE.svg';
//import { SearchHistory } from '../SearchHistory/search-history'

export const FirstEnter = () => {
  const tagsList = ['SD', 'AP', 'JS'];

  return (
    <Stack direction="column" width="100%" justifyContent="flex-start" px={24} pt={100}>
      <Stack direction="row" justifyContent="left" alignItems="center" sx={{ userSelect: 'none' }}>
        <Typography variant="subtitle1" fontWeight={500} maxWidth={140} position="relative">
          Add filters to begin your search
          <Box sx={{ position: 'absolute', top: -14, transform: 'translateX(-50%)' }}>
            <ArrowIcon />
          </Box>
        </Typography>
      </Stack>
      <Stack
        direction="column"
        gap={56}
        justifyContent="center"
        textAlign="center"
        alignItems="center"
      >
        <Stack gap={24} alignItems="center">
          <Stack maxWidth={300} gap={8} width="100%">
            {tagsList.map((tag, index) => (
              <Stack
                width="100%"
                alignItems={index % 2 === 0 ? 'flex-start' : 'flex-end'}
                key={index}
              >
                <Stack
                  py={8}
                  pl={16}
                  pr={40}
                  gap={8}
                  width={210}
                  sx={{ backgroundColor: (theme) => theme.palette.primary.contrastText }}
                  borderRadius="8px"
                  direction="row"
                  alignItems="center"
                  boxShadow="-4px 4px 20px #00000020"
                >
                  <Stack
                    p={20}
                    sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
                    borderRadius="50%"
                    position="relative"
                  >
                    <Typography
                      color="primary.contrastText"
                      position="absolute"
                      top="50%"
                      left="50%"
                      sx={{ transform: 'translate(-50%,-50%)' }}
                      variant="h4"
                    >
                      {tag}
                    </Typography>
                  </Stack>
                  <Stack width="100%" justifyContent="space-between" height="90%">
                    <Stack
                      sx={{ backgroundColor: '#3626A780' }}
                      height={10}
                      width="50%"
                      borderRadius={30}
                    ></Stack>
                    <Stack
                      sx={{
                        background: '#3626A720',
                      }}
                      height={10}
                      width="100%"
                      borderRadius={30}
                    ></Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Typography variant="h5" maxWidth={368}>
            Start your people search by applying any filter in the left panel
          </Typography>
        </Stack>
        {/* <SearchHistory /> */}
      </Stack>
    </Stack>
  );
};
