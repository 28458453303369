import ExportButton from '@features/companies/buttons/export-button';
import CompanyFilters from '@features/companies/filters/company-filters';
import { CompanyFilterProvider } from '@features/companies/filters/hooks/filter-context';
import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import DataLayout from './data-layout';

const CompaniesLayout = ({ children }: PropsWithChildren) => {
  return (
    <CompanyFilterProvider>
      <DataLayout
        type={'companies'}
        header={
          <Stack px={16}>
            <ExportButton />
          </Stack>
        }
        filter={<CompanyFilters />}
      >
        {children}
      </DataLayout>
    </CompanyFilterProvider>
  );
};

export default CompaniesLayout;
