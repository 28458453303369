import { Button, Typography } from '@mui/material';

const ManageMailboxButton = ({ openMailbox }: { openMailbox: () => void }) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={openMailbox}
      sx={{
        py: 4,
        px: 16,
        height: 32,
        borderRadius: (theme) => theme.spacing(26),
        borderColor: 'customGray1.light',
      }}
    >
      <Typography variant="subtitle1" color={'text.primary'}>
        Link mailbox
      </Typography>
    </Button>
  );
};

export default ManageMailboxButton;
