//import { Typography, MenuItem, CircularProgress, Stack } from '@mui/material'
import { Autocomplete, Box, MenuItem, Stack, SxProps, TextField } from '@mui/material';
//import { FilterSelect } from '../../../../shared/ui/selects'
//import React from 'react'
import { IIndustry } from '@shared/api/services/contact-service';
import { FC, useState } from 'react';

import { ReactComponent as Close } from '../../../../shared/assets/icons/close-filter-icon.svg';
import { ReactComponent as Down } from '../../../../shared/assets/icons/gray-arrow-bottom.svg';
import { ReactComponent as Search } from '../../../../shared/assets/icons/search-filter.svg';
import { useContactFilter } from '../hooks/filter-context';
import { ContactFilterProps } from '../interfaces';

const IndustryFilter: FC<ContactFilterProps> = ({ industryList, isLoading, ...props }) => {
  const { setFilterInputs, filters } = useContactFilter();
  const [inputValue, setInputValue] = useState(
    industryList?.find((value) => filters?.countries === value.id)?.name || ''
  );

  const inputSx: SxProps = {
    '& .MuiInputBase-root': { p: 1 },
    '& .MuiOutlinedInput-input': {},
    '& .MuiOutlinedInput-root': {
      pl: filters?.industries ? 8 : 30,
    },
  };

  return (
    <Autocomplete
      popupIcon={
        <Stack direction="row" py={6} px={4} justifyContent="center" alignItems="center">
          <Down />
        </Stack>
      }
      clearIcon={<Close />}
      value={industryList?.find((value) => filters?.industries === value.id) || null}
      onChange={(_, newValue: IIndustry | null) => {
        setFilterInputs?.((prev) => ({
          ...prev,
          industries: newValue?.name.trim() === '' ? null : newValue?.id,
        }));
        setInputValue(industryList?.find((value) => filters?.countries === value.id)?.name || '');
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onReset={() =>
        setFilterInputs?.((prev) => ({
          ...prev,
          industries: null,
        }))
      }
      options={isLoading ? [] : industryList!}
      autoHighlight
      getOptionLabel={(option) => option.name}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderOption={({ key, ...props }, option) => (
        <MenuItem
          value={option.id}
          {...props}
          key={option.id + option.name}
          sx={{ fontSize: (theme) => theme.typography.subtitle2 }}
        >
          {option.name}
        </MenuItem>
      )}
      renderInput={(params) => (
        <>
          <Box sx={{ position: 'relative' }}>
            <TextField
              {...params}
              placeholder="Search by industry"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              sx={inputSx}
            />
            {!filters?.industries && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '7%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Search />
              </Box>
            )}
          </Box>
        </>
      )}
      {...props}
    />
  );
};

export default IndustryFilter;
