import { ContactFilters, SavedButton, TotalButton } from '@features/contacts';
import ExportButton from '@features/contacts/buttons/export-button';
import { ContactFiltersProvider } from '@features/contacts/filters/hooks/filter-context';
import { Stack } from '@mui/material';
import { IContactDataLayout } from '@widgets/data-layouts/types';

import DataLayout from './data-layout';

const ContactsLayout = ({ children, setShowSaved, showSaved }: IContactDataLayout) => {
  return (
    <ContactFiltersProvider>
      <DataLayout
        header={
          <Stack direction="row">
            <Stack
              direction="row"
              alignItems="center"
              gap={16}
              pr={16}
              sx={{ borderRight: (theme) => `1px solid ${theme.palette.customGray1.light}` }}
            >
              <TotalButton handleClick={() => setShowSaved(false)} isSaved={showSaved} />
              <SavedButton handleClick={() => setShowSaved(true)} isSaved={showSaved} />
            </Stack>
            <Stack px={16}>
              <ExportButton />
            </Stack>
          </Stack>
        }
        filter={<ContactFilters />}
        type={'contacts'}
        show={showSaved}
      >
        {children}
      </DataLayout>
    </ContactFiltersProvider>
  );
};

export default ContactsLayout;
