import { Checkbox, Stack } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';

import { CompaniesStore } from '../model/companies-table.store';
import { IExportCheckboxField } from './types';

const ExportCheckboxField: FC<IExportCheckboxField> = ({ store }: { store: CompaniesStore }) => {
  const record = useRecordContext<IContact>();

  const [checked, setChecked] = useState(record ? store.isInExportQueue(record.id) : false);

  useEffect(() => {
    setChecked(record ? store.isInExportQueue(record.id) : false);
  }, [store, store.exportCompanies.length, record]);

  if (record) {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      store.toggleContactForExport(record.id);
      setChecked((prev) => !prev);
    };

    return (
      <Stack
        direction="row"
        justifyContent="center"
        height="100%"
        alignItems="flex-start"
        onClick={(e) => e.stopPropagation()}
      >
        <Checkbox size="small" checked={checked} onChange={(e) => onChange(e)} />
      </Stack>
    );
  } else {
    return null;
  }
};

export default observer(ExportCheckboxField);
