import { Stack, Typography } from '@mui/material';
import { authService } from '@shared/api/services/auth-service';
import { AppRoutes } from '@shared/interfaces';
import { useNotify } from 'ra-core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MessageIcon } from '../../../shared/assets/icons/mail.svg';
import { AuthTitle } from '../../../shared/ui/auth';
import { BackButton } from '../../../shared/ui/buttons';

const CheckEmailPage: FC = () => {
  const notify = useNotify();

  const navigate = useNavigate();

  const urlParams = new URL(window.location.href).searchParams;

  const step = urlParams.get('authFlowStep');
  const email = urlParams.get('email');

  const isForgotPasswordStep = step !== 'signUp';

  const onResendHandler = async () => {
    let handler = authService.forgotPassword;

    if (step === 'signUp') handler = authService.registrationResend;
    try {
      const { message } = await handler.call(authService, email || '');
      notify(message, {
        type: 'success',
      });
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e!.response?.data?.message || 'Something went wrong, try later', {
        type: 'error',
      });
    }
  };

  const onBackClickHandler = () => {
    if (step === 'signUp') {
      navigate(AppRoutes.SignUp);
      return;
    }
    navigate(AppRoutes.ForgotPassword);
  };

  return (
    <Stack>
      <Stack direction="row" mb={20}>
        <BackButton onClick={onBackClickHandler} />
      </Stack>
      <AuthTitle
        title="Check your email"
        subTitle={`We have sent a ${
          isForgotPasswordStep ? 'password reset' : 'verification account'
        } instructions to your email.`}
      />
      <Stack
        gap={16}
        p={24}
        borderRadius={(theme) => theme.spacing(14)}
        bgcolor="rgba(54, 38, 167, 0.05)"
      >
        <MessageIcon />
        <Typography variant="h4">
          Did not receive the email? Check your spam filter or{' '}
          <Typography
            component="span"
            sx={{ cursor: 'pointer' }}
            variant="h4"
            fontWeight={600}
            color="primary.main"
            onClick={onResendHandler}
          >
            resend the email
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CheckEmailPage;
