import { userStore } from '@pages/user-profile/model/user-profile.store';
import { UpgradeTypes } from '@shared/interfaces';
import { AxiosError, AxiosInstance } from 'axios';

import { eventEmmiter } from '@/app/App';

import withAuthorization from '../../withAuth';
import { IContact } from '../contact-service';
import { IGetResourceParams } from './admin.interfaces';

interface IError {
  stringCode: string;
}
class RequestError {
  public static check(error: AxiosError<IError>) {
    if (error.response?.data.stringCode === 'max_contact_page') {
      userStore.toggleUpgradePopup(UpgradeTypes.VIEW);
      eventEmmiter.emmit('toggle-popup');
    }
  }
}

class AdminService {
  authInstance: AxiosInstance;
  constructor(auth: AxiosInstance) {
    this.authInstance = auth;
  }

  async getResources(resource: string, params: IGetResourceParams) {
    const urlSearchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (typeof params[key as keyof IGetResourceParams] === 'object') {
        urlSearchParams.set(key, JSON.stringify(value));
      } else {
        urlSearchParams.set(key, `${value}`);
      }
    });
    try {
      //TODO move saved-contacts to contacts.service
      if (resource === 'saved-contacts') {
        resource = 'contacts/saved-contacts';
      }
      const response = await this.authInstance.get<IContact[]>(
        `${resource}?${urlSearchParams.toString()}`
      );
      return response;
    } catch (e) {
      RequestError.check(e as AxiosError<IError>);
      throw e;
    }
  }

  async getResourceById(resource: string, id: string) {
    const response = await this.authInstance.get<IContact>(`${resource}/${id}`);
    return response;
  }

  async createResource(resource: string, data: unknown) {
    const response = await this.authInstance.post(`${resource}`, data);
    return response;
  }
}

export const adminService = new AdminService(withAuthorization);
