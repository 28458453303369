import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
// import { AppRoutes } from '../../../shared/interfaces'
import { AppRoutes } from '@shared/interfaces';
import { getValidLink } from '@shared/libs/validations';
import { FC } from 'react';
import { Link, useRecordContext } from 'react-admin';

import { ReactComponent as FacebookIcon } from '../../../shared/assets/icons/facebook-contact.svg';
import { ReactComponent as GithubIcon } from '../../../shared/assets/icons/github-contact.svg';
import { ReactComponent as LinkIcon } from '../../../shared/assets/icons/link-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../shared/assets/icons/linkedin-contact.svg';
import { ReactComponent as TwitterIcon } from '../../../shared/assets/icons/twitter-contact.svg';
import { IFullNameCompanyField } from './types';
//import useContactsStore from '../model/contact-table.store'

const FullNameCompanyField: FC<IFullNameCompanyField> = ({ isCompany }) => {
  //const contactsStore = useContactsStore()
  const record = useRecordContext<IContact>();
  if (!record) return null;
  const {
    full_name,
    linkedin_url,
    twitter_url,
    github_url,
    facebook_url,
    company_linkedin_url,
    company_twitter_url,
    company_facebook_url,
    company_url,
    company,
  } = record;

  // const currentWorkplace = contactsStore.currentWorkHistory?.find(job => job.is_primary)
  // const currentCompanyName =
  //   (contactsStore.currentUpdatedContact
  //     ? contactsStore.currentUpdatedContact.company
  //     : currentWorkplace?.company.name) ?? company

  // const socialsCompany = [
  //   {
  //     social: 'Site',
  //     url: currentWorkplace ? currentWorkplace?.company.website : company_url,
  //     icon: LinkIcon,
  //   },
  //   {
  //     social: 'Linkedin',
  //     url: currentWorkplace ? currentWorkplace?.company.linkedin_id : company_linkedin_url,
  //     icon: LinkedinIcon,
  //   },
  //   {
  //     social: 'Twitter',
  //     url: currentWorkplace ? currentWorkplace?.company.twitter_url : company_twitter_url,
  //     icon: TwitterIcon,
  //   },
  //   {
  //     social: 'Facebook',
  //     url: currentWorkplace ? currentWorkplace?.company.facebook_url : company_facebook_url,
  //     icon: FacebookIcon,
  //   },
  // ]

  const socialsFullName = [
    { social: 'Linkedin', url: linkedin_url, icon: LinkedinIcon },
    { social: 'Twitter', url: twitter_url, icon: TwitterIcon },
    { social: 'Github', url: github_url, icon: GithubIcon },
    { social: 'Facebook', url: facebook_url, icon: FacebookIcon },
  ];

  const socialsCompany = [
    { social: 'Site', url: company_url, icon: LinkIcon },
    { social: 'Linkedin', url: company_linkedin_url, icon: LinkedinIcon },
    { social: 'Twitter', url: company_twitter_url, icon: TwitterIcon },
    { social: 'Facebook', url: company_facebook_url, icon: FacebookIcon },
  ];
  const socials = isCompany ? socialsCompany : socialsFullName;
  const title = isCompany ? company : full_name;

  return (
    <Stack>
      <Tooltip title={title}>
        <Typography
          color="text.primary"
          variant="subtitle1"
          textOverflow="ellipsis"
          maxWidth="100%"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {
            !isCompany ? (
              <Link
                to={`${AppRoutes.Contacts}/${record.id}/show`}
                onClick={(e) => e.stopPropagation()}
              >
                {title}
              </Link>
            ) : (
              //<Link to={`${AppRoutes.Company}/${record.id}/show`} onClick={e => e.stopPropagation()}>
              title
            )
            //</Link>
          }
        </Typography>
      </Tooltip>

      <Stack direction="row" ml={-4} mt={-2}>
        {socials.map(
          ({ url, social, icon: Icon }) =>
            url && (
              <Tooltip title={getValidLink(url)} key={social} onClick={(e) => e.stopPropagation()}>
                <a href={getValidLink(url)} rel="noreferrer" target="_blank">
                  <IconButton sx={{ p: 4 }}>
                    <Icon />
                  </IconButton>
                </a>
              </Tooltip>
            )
        )}
      </Stack>
    </Stack>
  );
};

export default FullNameCompanyField;
