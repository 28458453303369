import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useListController } from 'react-admin';

interface ContactFilterContext {
  filters: {
    full_name?: string | null;
    job_title?: string | null;
    countries?: string | null;
    industries?: string | null;
  };
  setFilterInputs: Dispatch<SetStateAction<ContactFilterContext['filters']>>;
  applyFilter: () => void;
  resetAll: () => void;
  activeFiltersQuantity: number;
  isApplyButtonDisabled: boolean;
}

const Context = createContext<Partial<ContactFilterContext>>({});

const ContactFiltersProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setFilters, filterValues } = useListController();

  const [filterInputs, setFilterInputs] = useState<ContactFilterContext['filters']>(() => ({
    full_name: filterValues.full_name || null,
    job_title: filterValues.job_title || null,
    countries: filterValues.countries || null,
    industries: filterValues.industries || null,
  }));

  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

  useEffect(() => {
    let isButtonDisabled = true;
    Object.entries(filterInputs).forEach(([key, value]) => {
      const urlFilterValue = filterValues[key];
      if (!urlFilterValue && value !== null) isButtonDisabled = false;
      if (urlFilterValue && value !== urlFilterValue) isButtonDisabled = false;
    });
    setIsApplyButtonDisabled(isButtonDisabled);
  }, [filterInputs, filterValues]);

  const resetAll = () => {
    const resetFilters: Partial<ContactFilterContext['filters']> = {};
    Object.keys(filterInputs).forEach((key) => {
      resetFilters[key as 'full_name'] = null;
    });
    setFilterInputs(resetFilters);
    setFilters({}, {});
    setIsApplyButtonDisabled(true);
  };

  const activeFiltersQuantity = Object.entries(filterValues).reduce((acc, filter) => {
    return acc + +Boolean(filter);
  }, 0);

  const applyFilter = useCallback(() => {
    const newValues: Record<string, unknown> = {};
    Object.entries(filterInputs).forEach(([key, value]) => {
      if (value) newValues[key] = value;
    });
    setFilters(newValues, {});
  }, [filterInputs, setFilters]);

  const contextValue: ContactFilterContext = {
    filters: filterInputs,
    setFilterInputs,
    applyFilter,
    resetAll,
    activeFiltersQuantity,
    isApplyButtonDisabled,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useContactFilter = () => useContext(Context);

export { ContactFiltersProvider };
