import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { ReactComponent as GoogleIcon } from '@shared/assets/icons/mailbox-icons/google.svg';
import { ReactComponent as MailIcon } from '@shared/assets/icons/mailbox-icons/mail.svg';
import { ReactComponent as OutlookIcon } from '@shared/assets/icons/mailbox-icons/outlook.svg';
import React, { SVGProps } from 'react';

const useStyles = makeStyles({
  dialog: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    position: 'absolute !important',
    left: '50%',
    transform: 'translateX(-50%)',
    top: 100,
    width: 650,
    maxWidth: 650,
  },
});

interface ICreateMailboxModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateMailboxModal = ({ open, setOpen }: ICreateMailboxModal) => {
  const [value, setValue] = React.useState('google');
  const classes = useStyles();

  const linkMailbox = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={64}
        sx={{
          borderBottom: ' 1px solid #dfdfdf',
          fontWeight: 'bold',
        }}
      >
        <Typography fontSize={26} fontWeight={700} color="black">
          Let’s link your mailbox
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Stack direction="column" px={36} py={16} spacing={24}>
        <Typography variant="subtitle1">
          Link your mailboxes with Apollo to gain full functionality of core engagement tools, like
          emails, sequences, conversations, meetings and more.
        </Typography>
        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: 'text.primary', fontSize: '24px', fontWeight: '700' }}
          >
            Choose your email provider
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            sx={{
              display: 'grid',
              gridTemplateRows: '1fr',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: '8px',
              '& > .MuiFormControlLabel-root': {
                margin: '0',
              },
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <FormControlLabel
              control={
                <CreateMailboxRadio
                  Icon={GoogleIcon}
                  title={'Google'}
                  description={'GMail / GSuite'}
                  value="google"
                  selectedValue={value}
                />
              }
              label=""
            />
            <FormControlLabel
              control={
                <CreateMailboxRadio
                  Icon={OutlookIcon}
                  title={'Outlook'}
                  description={'Hotmail, Live, MSN'}
                  value="outlook"
                  selectedValue={value}
                />
              }
              label=""
            />
            <FormControlLabel
              control={
                <CreateMailboxRadio
                  Icon={MailIcon}
                  title={'Other'}
                  description={'Any provider, IMAP'}
                  value="custom"
                  selectedValue={value}
                />
              }
              label=""
            />
          </RadioGroup>
        </FormControl>
        <Stack direction="row" spacing={24}>
          <Checkbox sx={{ width: 'fit-content', height: 'fit-content' }} />
          <Typography variant="subtitle2">
            {`I agree to Apollo’s Terms of Service and Privacy Policy and will not use Apollo to send
            any spam, unsolicited or harassing emails (commercial or otherwise) in violation of any
            applicable laws. By clicking "Link mailbox" below, I acknowledge that business contact
            data submitted from my email account to Apollo may be used to provide and improve
            Apollo's services as further described in our Terms of Service. Learn more about data
            sharing.`}
          </Typography>
        </Stack>
      </Stack>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          sx={{ fontSize: 16, lineHeight: 1, height: 'fit-content', width: 'fit-content' }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ fontSize: 16, lineHeight: 1, height: 'fit-content', width: 'fit-content' }}
          onClick={linkMailbox}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateMailboxRadio = ({
  Icon,
  title,
  description,
  value,
  selectedValue,
}: {
  Icon: React.FC<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  value: string;
  selectedValue?: string;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={8}
      border="1px solid"
      borderColor={selectedValue === value ? 'primary.main' : 'customGray2.light'}
      sx={{
        aspectRatio: 1,
      }}
    >
      <Radio sx={{ display: 'none' }} value={value} />
      <Icon style={{ width: '33%', height: 'auto' }} />
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle1">{description}</Typography>
    </Box>
  );
};

export default CreateMailboxModal;
