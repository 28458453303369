import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';

import { getDateName } from '@/shared/libs';
import { getNewDate } from '@/shared/libs/getNewDate';

import { ITotalPriceConfirmation } from './types';

const TotalPriceConfirmation = ({
  isProgress,
  stripe,
  elements,
  price,
}: ITotalPriceConfirmation) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const endDate = getDateName(getNewDate(String(currentDate), 1), true);
  return isProgress ? (
    <Stack height="70px" alignItems="center" justifyContent="center">
      <CircularProgress size={70} />
    </Stack>
  ) : (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight={600}>
          {`${price.name} Plan`}
        </Typography>
        <Typography variant="h6">{price.symbol + price.plan}</Typography>
      </Stack>
      {price.phone !== '0' && (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight={600}>
            Email credits
          </Typography>
          <Typography variant="h6">{price.symbol + price.phone}</Typography>
        </Stack>
      )}
      {price.phone !== '0' && (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight={600}>
            Phone credint
          </Typography>
          <Typography variant="h6">{price.symbol + price.email}</Typography>
        </Stack>
      )}
      <Divider sx={{ borderBottomColor: '#b7aeae' }} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight={600}>
          Total
        </Typography>
        <Typography variant="h6">{price.symbol + price.total}</Typography>
      </Stack>
      <Typography variant="subtitle1" style={{ marginTop: '0.6rem' }}>
        Your subscription will be active until {endDate}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '0.3rem' }}>
        *We will save your payment method for subsequent subscription payment
      </Typography>
      <>
        <Button
          type="submit"
          variant="contained"
          disabled={!stripe || !elements}
          style={{ fontSize: '1.1rem', fontWeight: 700 }}
        >
          Pay
        </Button>
      </>
    </Stack>
  );
};

export default TotalPriceConfirmation;
