import { Stack } from '@mui/material';
import { IFullCompany } from '@shared/api/services/company-service';

import { CurrentCompany } from './current-company/current-company';

export const RightSide = ({ company }: { company: IFullCompany }) => {
  return (
    <Stack direction="column" gap={32} width="100%" minWidth={460}>
      <CurrentCompany company={company} />
      {/*<Insights />*/}
    </Stack>
  );
};
