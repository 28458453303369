import axios from 'axios';

import apiURL from './baseURL';
import TokenService from './tokenService';

const withAuthorization = axios.create({
  baseURL: apiURL,
});

interface IRefreshPayload {
  token: string;
}

interface IRefreshResponse {
  data: {
    accessToken: string;
  };
}

withAuthorization.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      throw new axios.Cancel('Not login');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

withAuthorization.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (err.response?.data?.statusCode === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        const response = await withAuthorization.post<null, IRefreshResponse, IRefreshPayload>(
          '/auth/refresh-tokens',
          {
            token: TokenService.getLocalRefreshToken()!,
          }
        );
        const { accessToken } = response.data;
        TokenService.updateLocalAccessToken(accessToken);
        axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
        return withAuthorization(originalConfig);
      } catch (_error) {
        TokenService.deleteTokens();
        document.location.reload();
        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  }
);

export default withAuthorization;
