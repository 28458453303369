import { Grid, Stack } from '@mui/material';
import { FC } from 'react';

import { IAuthFlowLayout } from './auth-flow-layout.interfaces';
import AuthRightSideBar from './auth-right-side-bar';

const AuthFlowLayout: FC<IAuthFlowLayout> = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        minHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <Grid item xs={!/Mobi/.test(navigator.userAgent) ? 6 : 12}>
        <Stack
          justifyContent="space-between"
          py={66}
          px={16}
          maxWidth="416px"
          m="auto"
          height="100%"
        >
          {children}
        </Stack>
      </Grid>
      {!/Mobi/.test(navigator.userAgent) && (
        <Grid item xs={6} bgcolor="customGray2.main">
          <AuthRightSideBar />
        </Grid>
      )}
    </Grid>
  );
};

export default AuthFlowLayout;
