import { authService } from '@shared/api/services/auth-service';
import { ILoginDto } from '@shared/api/services/auth-service/auth.dto';
import { AuthProvider } from 'ra-core';

import TokenService from '../shared/api/tokenService';

const authProvider: AuthProvider = {
  isAuthenticated() {
    return !!TokenService.getLocalAccessToken() && !!TokenService.getLocalRefreshToken();
  },

  async login(dto: ILoginDto) {
    try {
      const data = await authService.login(dto);
      return Promise.resolve(data);
    } catch (e: unknown) {
      return Promise.reject(e);
    }
  },

  async logout() {
    TokenService.deleteTokens();
    return Promise.resolve();
  },

  async checkAuth() {
    const accessToken = TokenService.getLocalAccessToken();
    const refreshToken = TokenService.getLocalRefreshToken();
    if (!accessToken || !refreshToken) return Promise.reject();
    return Promise.resolve();
  },

  async checkError(err) {
    if (err.response?.data?.statusCode === 401) Promise.reject();
    return Promise.resolve();
  },

  async getPermissions() {
    return Promise.resolve();
  },
};

export default authProvider;
