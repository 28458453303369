import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Button, Stack } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as CloseIcon } from '../../../shared/assets/icons/iconCloseWhite.svg';
//import { ReactComponent as VerifyOpenIcon } from '../../../shared/assets/icons/open-verify.svg'
import { ReactComponent as VerifyIcon } from '../../../shared/assets/icons/verify.svg';
import c from './style.module.scss';
import { ISpinButton } from './types';

export const CombineIcon: FC<{ icon: FC; isOpen?: boolean; isExists: boolean }> = ({
  icon: Icon,
  isOpen = false,
  isExists,
}) => {
  return (
    <>
      <Icon />
      <Box position="absolute" top={isOpen && !isExists ? 11 : 4.5} right={8}>
        {!isOpen && !isExists && (
          <Stack
            sx={{
              width: 12,
              height: 12,
              pt: 12.5,
            }}
            height="100%"
            alignItems="flex-end"
            justifyContent="center"
          >
            <HelpIcon
              fontSize="inherit"
              sx={{
                width: '100%',
                height: 12,
                borderRadius: '50%',
                backgroundColor: (theme) => theme.palette.secondary.main,
              }}
            />
          </Stack>
        )}
        {isOpen && isExists && <VerifyIcon />}
        {isOpen && !isExists && (
          <Stack
            sx={{
              backgroundColor: (theme) => theme.palette.warning.main,
              width: 12,
              height: 12,
              borderRadius: '50%',
              border: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <CloseIcon />
          </Stack>
        )}
      </Box>
    </>
  );
};

const SpinButton: FC<ISpinButton> = ({
  isLoading,
  icon: Icon,
  isOpen = false,
  isExists = false,
  ...rest
}) => {
  return (
    <Button
      variant="outlined"
      sx={{
        position: 'relative',
        height: 44,
        minWidth: 44,
        borderColor: 'customGray1.light',
        bgcolor: 'transparent',
        p: 12,
      }}
      {...rest}
    >
      {isLoading ? (
        <AutorenewRoundedIcon className={c.spin} />
      ) : (
        <CombineIcon icon={Icon} isOpen={isOpen} isExists={isExists} />
      )}
    </Button>
  );
};

export default SpinButton;
