import {
  ICompanyLocationListIds,
  ICompanyLocationResponse,
  iFullCompanyResponse,
  ISimpleCompanyResponse,
} from '@shared/api/services/company-service/company.interfaces';
import { IExportedContactsResult } from '@shared/api/services/contact-service';
import { AxiosInstance } from 'axios';
import { GetListParams } from 'react-admin';

import withAuthorization from '../../withAuth';

class CompaniesService {
  authRequest: AxiosInstance;

  constructor(authRequest: AxiosInstance) {
    this.authRequest = authRequest;
  }

  async getList(params: GetListParams) {
    const queryParams = new URLSearchParams();
    queryParams.append('page', params.pagination.page.toString());
    queryParams.append('limit', params.pagination.perPage.toString());
    Object.entries(params.filter).forEach(([key, value]) => {
      queryParams.append(key, String(value));
    });
    const { data } = await this.authRequest.get<ISimpleCompanyResponse>(
      `companies/filter?${queryParams.toString()}`
    );

    return data;
  }

  async getOne(url: string) {
    if (!url) {
      return null;
    }

    await Promise.allSettled([
      this.authRequest.post(`scrap/facebook/company/offline`, { url }),
      this.authRequest.post(`scrap/company/offline`, { url }),
    ]);

    const { data } = await this.authRequest.post<iFullCompanyResponse>(`companies/get-full-info`, {
      url,
    });

    return data;
  }

  async getLocationList(searchTerm: string): Promise<ICompanyLocationListIds> {
    const { data } = await this.authRequest.post<ICompanyLocationResponse>(`companies/locations`, {
      searchTerm,
    });

    let id = 0;

    data.cities = data.cities.map((el) => {
      id += 1;
      return {
        ...el,
        id,
      };
    });
    data.regions = data.regions.map((el) => {
      id += 1;
      return {
        ...el,
        id,
      };
    });
    data.countries = data.countries.map((el) => {
      id += 1;
      return {
        ...el,
        id,
      };
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data;
  }

  async exportSelectedCompanies(
    companies: { id: string; domain: string }[]
  ): Promise<{ file?: string; response?: IExportedContactsResult }> {
    const { data } = await this.authRequest.post<{
      file?: string;
      response?: IExportedContactsResult;
    }>(`company/export/csv`, { domains: companies.map((company) => company.domain) });
    return data;
  }
}

export const companiesService = new CompaniesService(withAuthorization);
