import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

const AuthTitle = ({ title, subTitle }: { title: string; subTitle: string }) => {
  return (
    <Stack gap={8} mb={48}>
      <Typography variant="h2">{title}</Typography>
      {subTitle && <Typography variant="subtitle2">{subTitle}</Typography>}
    </Stack>
  );
};

export default AuthTitle;
