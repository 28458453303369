import { CompaniesStore } from '@entities/companies-table/model/companies-table.store';
import { ContactsStore } from '@entities/contact-table/model/contact-table.store';
import { Checkbox, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

export const CheckboxHeader = observer(function ({
  store,
}: {
  store: ContactsStore | CompaniesStore;
}) {
  const { exportAllSelector: exportAll } = store;
  const handleSelectAll = () => {
    store.toggleSelectAllForExport(!exportAll);
  };

  useEffect(() => {
    return () => store.resetAllExports();
  }, [store]);

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Checkbox
        size="small"
        checked={exportAll}
        onChange={() => handleSelectAll()}
        disabled={
          !(store instanceof ContactsStore ? store.currentContacts : store.currentCompanies).length
        }
      />
    </Stack>
  );
});
