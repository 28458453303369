import { Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

// import { useSubscriptionStore } from '../../../entities/subscription';
// import SaleImage from '../../../shared/assets/images/20percentHint.png';

const DataPlansChangeFilter = () => {
  // const subscription = useSubscriptionStore();
  // const isMonthPlan = subscription.isMonthPlans;

  // const textColorMonth = isMonthPlan ? 'primary.main' : 'text.secondary';
  // const textVariantMonth = isMonthPlan ? 'subtitle1' : 'subtitle2';
  //
  // const textVariantYear = !isMonthPlan ? 'subtitle1' : 'subtitle2';
  // const textColorYear = !isMonthPlan ? 'primary.main' : 'text.secondary';
  //
  // const monthBtnType = isMonthPlan ? 'contained' : 'text';
  // const yearBtnType = !isMonthPlan ? 'contained' : 'text';
  //
  // const monthBgColor = isMonthPlan ? '#fff' : '';
  // const yearBgColor = !isMonthPlan ? '#fff' : '';
  //
  // const onSwitchClick = (type: 'month' | 'year') => () => {
  //   if ((type === 'month' && isMonthPlan) || (type === 'year' && !isMonthPlan)) return;
  //   subscription.toggleIsMonthPlans();
  // };

  return (
    <Stack maxWidth={327} alignItems="center" gap={24}>
      <Typography variant="h2" textAlign="center">
        We’ve got a plan that’s perfect for you
      </Typography>
      {/*<Stack*/}
      {/*  bgcolor="customGray2.main"*/}
      {/*  direction="row"*/}
      {/*  borderRadius={(theme) => theme.spacing(8)}*/}
      {/*  gap={4}*/}
      {/*  p={4}*/}
      {/*  position="relative"*/}
      {/*>*/}
      {/*  <Button*/}
      {/*    variant={monthBtnType}*/}
      {/*    color="inherit"*/}
      {/*    sx={{*/}
      {/*      bgcolor: monthBgColor,*/}
      {/*      py: 10,*/}
      {/*      px: 24,*/}
      {/*    }}*/}
      {/*    onClick={onSwitchClick('month')}*/}
      {/*  >*/}
      {/*    <Typography variant={textVariantMonth} color={textColorMonth}>*/}
      {/*      Monthly plans*/}
      {/*    </Typography>*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    variant={yearBtnType}*/}
      {/*    color="inherit"*/}
      {/*    sx={{*/}
      {/*      py: 10,*/}
      {/*      px: 24,*/}
      {/*      bgcolor: yearBgColor,*/}
      {/*    }}*/}
      {/*    onClick={onSwitchClick('year')}*/}
      {/*  >*/}
      {/*    <Typography variant={textVariantYear} color={textColorYear}>*/}
      {/*      Yearly plans*/}
      {/*    </Typography>*/}
      {/*  </Button>*/}
      {/*  <Stack*/}
      {/*    direction="row"*/}
      {/*    justifyContent="left"*/}
      {/*    alignItems="center"*/}
      {/*    position="absolute"*/}
      {/*    bottom={-6}*/}
      {/*    right={-120}*/}
      {/*    sx={{ userSelect: 'none' }}*/}
      {/*  >*/}
      {/*    <Box>*/}
      {/*      <img src={SaleImage} alt="Save 20%" loading="lazy" />*/}
      {/*    </Box>*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
    </Stack>
  );
};

export default observer(DataPlansChangeFilter);
