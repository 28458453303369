import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { authService } from '@shared/api/services/auth-service';
import { AppRoutes } from '@shared/interfaces';
import { useNotify } from 'ra-core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TokenService from '../../../shared/api/tokenService';
import { AuthTitle } from '../../../shared/ui/auth';
import { InputField } from '../../../shared/ui/inputs';

const VerificationPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const notify = useNotify();

  const navigate = useNavigate();

  const params = new URL(window.location.href).searchParams;
  const code = params.get('code');

  const onVerifyClickHandler = async () => {
    setIsLoading(true);
    try {
      const email = await authService.getEmailByCode(code!);
      let newEmail = email?.trim();
      if (newEmail?.includes(' ')) newEmail = newEmail?.replaceAll(' ', '+');
      const { accessToken, refreshToken } = await authService.verification({
        code: code!,
        email: newEmail!,
      });
      TokenService.updateLocalAccessToken(accessToken);
      TokenService.updateLocalRefreshToken(refreshToken);
      navigate(AppRoutes.Base, {
        replace: true,
      });
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e.response?.data?.message || 'Something went wrong, try later', {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <AuthTitle title="Verification" subTitle="" />
      <Stack mb={24}>
        <InputField value={code} disabled title="Verification code" />
      </Stack>
      <Button variant="contained" onClick={onVerifyClickHandler} disabled={isLoading}>
        Click to Verify
      </Button>
    </Stack>
  );
};

export default VerificationPage;
