import { FC } from 'react';

import { FilterInput } from '../../../../shared/ui/inputs';
import { ContactFilterProps } from '../interfaces';

const JobTitleFilter: FC<ContactFilterProps> = (props) => {
  return <FilterInput {...props} filter="job_title" placeholder="Search by job title" />;
};

export default JobTitleFilter;
