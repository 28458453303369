import { useCallback, useEffect } from 'react';

const usePreventRefreshing = (refisShouldStopRefreshing: React.MutableRefObject<boolean>) => {
  const confirmExit = useCallback(
    (e: BeforeUnloadEvent) => {
      if (!refisShouldStopRefreshing.current) {
        return;
      }
      e.preventDefault();
      e.returnValue = true;
      return 'show warning';
    },
    [refisShouldStopRefreshing]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', confirmExit);
    return () => window.removeEventListener('beforeunload', confirmExit);
  }, [confirmExit]);
};

export default usePreventRefreshing;
