import { makeStringFromObjectLocationCity } from '@shared/libs/locations/makeStringFromObjectLocationCity';
import { makeStringFromObjectLocationCountry } from '@shared/libs/locations/makeStringFromObjectLocationCountry';
import { makeStringFromObjectLocationRegion } from '@shared/libs/locations/makeStringFromObjectLocationRegion';

export const makeStringFromObjectLocation = (obj: unknown) => {
  if (Object.prototype.hasOwnProperty.call(obj, 'city')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return makeStringFromObjectLocationCity(obj);
  } else if (Object.prototype.hasOwnProperty.call(obj, 'region')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return makeStringFromObjectLocationRegion(obj);
  } else if (Object.prototype.hasOwnProperty.call(obj, 'country_code')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return makeStringFromObjectLocationCountry(obj);
  }

  return '';
};
