import { Button, Stack } from '@mui/material';
import { authService } from '@shared/api/services/auth-service';
import { AppRoutes } from '@shared/interfaces';
import { isEmailValid } from '@shared/libs/validations';
import { ChangeEvent, useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { AuthTitle } from '../../../shared/ui/auth';
import { BackButton } from '../../../shared/ui/buttons';
import { InputField } from '../../../shared/ui/inputs';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const notify = useNotify();

  const onBackClickHandler = () => {
    navigate(AppRoutes.Login);
  };

  const onEmailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSendInstructionsHandler = async () => {
    const isValidEmail = isEmailValid(email);
    if (!isValidEmail) {
      notify('Input valid email', { type: 'error' });
      return;
    }

    setIsLoading(true);
    try {
      const { message } = await authService.forgotPassword(email);
      notify(message, { type: 'success' });
      const urlParams = new URL(window.location.href).searchParams;
      urlParams.set('email', email);
      urlParams.set('authFlowStep', 'forgotPassword');
      navigate(`${AppRoutes.CheckEmail}?${urlParams.toString()}`);
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e?.response?.data?.message || 'Try later', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      <Stack direction="row" mb={20}>
        <BackButton onClick={onBackClickHandler} />
      </Stack>
      <AuthTitle
        title="Forgot password"
        subTitle="Enter the email associated with your account and we’ll send an email with instructions to reset your password."
      />
      <InputField
        title="Email"
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={onEmailChangeHandler}
      />
      <Stack mt={40}>
        <Button variant="contained" disabled={isLoading} onClick={onSendInstructionsHandler}>
          Send instructions
        </Button>
      </Stack>
    </Stack>
  );
};

export default ForgotPasswordPage;
