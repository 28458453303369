import { Button, Typography } from '@mui/material';
import { formatNumber } from '@shared/libs/formatNumber';
import { observer } from 'mobx-react-lite';

import { useContactsStore } from '../../../entities/contact-table';
import { ReactComponent as TotalIcon } from '../../../shared/assets/icons/contacts-total.svg';
import { ReactComponent as TotalIconBlack } from '../../../shared/assets/icons/contacts-total-black.svg';

const TotalButton = ({ handleClick, isSaved }: { handleClick: () => void; isSaved: boolean }) => {
  const { total } = useContactsStore();

  return (
    <Button
      variant={!isSaved ? 'contained' : 'outlined'}
      color="secondary"
      startIcon={!isSaved ? <TotalIcon /> : <TotalIconBlack />}
      onClick={handleClick}
      sx={{
        py: 4,
        px: 16,
        height: 32,
        borderRadius: (theme) => theme.spacing(26),
        borderColor: !isSaved ? 'none' : 'customGray1.light',
      }}
    >
      <Typography variant="subtitle1" color={!isSaved ? 'primary.main' : 'text.primary'}>
        Total ({formatNumber(total)})
      </Typography>
    </Button>
  );
};

export default observer(TotalButton);
