import CompanyFilterInput from '@shared/components/inputs/company-filter-input';
import { FC } from 'react';

import { ContactFilterProps } from '../interfaces';

const FullNameFilter: FC<ContactFilterProps> = (props) => {
  return <CompanyFilterInput {...props} filter="name" placeholder="Search by name" />;
};

export default FullNameFilter;
