import { Card, CardContent, Typography } from '@mui/material';
import * as React from 'react';

import Popup from '../../../app/components/popup';
import CardButton from './payment-card-button';

interface PaymentCardProps {
  cardType: string;
  lastFourDigits: string;
  onDelete: () => void;
}

const PaymentCard: React.FC<PaymentCardProps> = ({ cardType, lastFourDigits, onDelete }) => {
  const maskedDigits = `**** **** **** ${lastFourDigits}`;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    onDelete();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{cardType}</Typography>
        <Typography variant="body1">Card numbers: {maskedDigits}</Typography>
        <CardButton buttonText="Delete card" onClick={handleOpen} />
        <Popup
          open={open}
          onClose={handleClose}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          text="Are you sure you want to delete this card?"
        />
      </CardContent>
    </Card>
  );
};

export default PaymentCard;
