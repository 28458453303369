import { Button, Typography } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { AppRoutes } from '@shared/interfaces';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-admin';

const AvatarButton = observer(() => {
  const userProfile = useUserProfile();

  const userSymbols = userProfile.currentProfile
    ? userProfile.currentProfile.firstName[0].toLocaleUpperCase() +
      userProfile.currentProfile.lastName[0].toLocaleUpperCase()
    : 'KM';

  return (
    <Link to={`${AppRoutes.Profile}`}>
      <Button
        variant="outlined"
        color="inherit"
        sx={{
          background: (theme) => theme.palette.customGray2.main,
          border: (theme) => `1px solid ${theme.palette.customGray1.light}`,
          borderRadius: '6px',
          px: 2,
          py: 4,
          minWidth: 32,
          minHeight: 32,
        }}
      >
        <Typography color="text.secondary" variant="body2" lineHeight={2} fontWeight={600}>
          {userSymbols}
        </Typography>
      </Button>
    </Link>
  );
});

export default AvatarButton;
