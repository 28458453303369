import { FC } from 'react';

import { FilterInput } from '../../../../shared/ui/inputs';
import { ContactFilterProps } from '../interfaces';

const FullNameFilter: FC<ContactFilterProps> = (props) => {
  return <FilterInput {...props} filter="full_name" placeholder="Search by name" />;
};

export default FullNameFilter;
