import { FC, useEffect, useState } from 'react';

import c from './style.module.scss';
import { IProgressiveImage } from './types';

const ProgressiveImage: FC<IProgressiveImage> = ({
  src,
  placeholderSrc,
  className = '',
  alt = '',
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const classes = `${
    placeholderSrc && imgSrc === placeholderSrc ? c.loading : c.loaded
  } ${className}`;

  return <img src={imgSrc} className={classes} alt={alt} {...rest} />;
};

export default ProgressiveImage;
