import { Button, Typography } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as LeftArrow } from '../../assets/icons/left.svg';
import { IBackButton } from './types';

const BackButton: FC<IBackButton> = ({ ...rest }) => {
  return (
    <Button variant="text" color="inherit" {...rest} startIcon={<LeftArrow />}>
      <Typography color="customGray1.dark" variant="subtitle1">
        Back
      </Typography>
    </Button>
  );
};

export default BackButton;
