import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { LeftSide } from '@pages/company/left-side/left-side';
import { RightSide } from '@pages/company/right-side/right-side';
import { IFullCompany } from '@shared/api/services/company-service';
import { FC } from 'react';
import { useGetOne } from 'react-admin';

import styles from './CompanySidebar.module.scss';

export const CompanySidebar: FC<{ id: string | undefined | null }> = ({ id }) => {
  const { data, isLoading } = useGetOne<IFullCompany>('companies', { id: id });

  return (
    <Box className={'testing'} height="100%" overflow="hidden">
      <Stack
        className={styles.scrollbar}
        direction="column"
        gap={16}
        maxWidth={492}
        bgcolor="white"
        p={16}
        pt={40}
        boxShadow="-4px 0px 20px rgba(111, 113, 122, 0.2)"
        position="relative"
        height="100vh"
      >
        {isLoading && (
          <Stack direction="row" gap={8} alignItems="center">
            <Typography variant="subtitle1">Updating...</Typography>
            <CircularProgress size={20} />
          </Stack>
        )}
        {data && (
          <>
            <LeftSide company={data} />
            <RightSide company={data} />
          </>
        )}
      </Stack>
    </Box>
  );
};
