import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, ButtonProps, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { contactService } from '@shared/api/services/contact-service';
import { CreditTypes, UpgradeTypes } from '@shared/interfaces';
import { copyInBuffer } from '@shared/libs/validations';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNotify } from 'react-admin';

import { eventEmmiter } from '@/app/App';

import { contactsStore, useContactsStore } from '../../../entities/contact-table';
import { ReactComponent as PhoneIcon } from '../../../shared/assets/icons/iconPhone.svg';
import { ReactComponent as BlackPhoneIcon } from '../../../shared/assets/icons/iconPhoneBlack.svg';
import { ReactComponent as WhitePhoneIcon } from '../../../shared/assets/icons/iconPhoneWhite.svg';
import { CopyIconButton } from '../../../shared/ui/buttons';
import SpinButton from './spin-button';

export const PhoneButton: FC<{
  contactId: string;
  HQ?: string;
  isTable?: boolean;
  isEmailExist?: boolean;
  openedPhones?: string[];
  isPhoneOpened?: boolean;
  setIsPhoneExists?: Dispatch<SetStateAction<boolean>>;
}> = observer(
  ({
    contactId,
    isTable = true,
    isEmailExist = false,
    setIsPhoneExists,
    openedPhones,
    isPhoneOpened,
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const userProfile = useUserProfile();
    const contactStore = useContactsStore();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [phone, setPhone] = useState<null | string[]>(
      openedPhones?.length
        ? openedPhones
        : () => {
            const state = contactStore.getSavedById(contactId);
            if (!state) return null;

            return state.phoneNumbers ?? null;
          }
    );

    useEffect(() => {
      const dispose = autorun(() => {
        const item = contactStore.getSavedById(contactId);

        if (item?.phoneNumbers) {
          setPhone(item.phoneNumbers);
          if (setIsPhoneExists) setIsPhoneExists(true);
        }
      });

      return () => dispose();
    }, [contactId, contactStore, setIsPhoneExists]);

    const numberRef = useRef<HTMLParagraphElement | null>(null);

    const notify = useNotify();

    const onPhoneClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const onCloseHandler = () => {
      setAnchorEl(null);
    };

    const onRequestNumberHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setIsLoading(true);
      setAnchorEl(null);
      try {
        const phoneData = await contactService.savePhone(contactId);
        const contact = contactsStore.getContactById(contactId)!;

        if (!phoneData.length) {
          setPhone(['']);
          contactStore.addSavedContact({ ...contact, phoneNumbers: phoneData });

          if (setIsPhoneExists) {
            setIsPhoneExists(true);
          }
          return;
        }
        setPhone(phoneData);

        contactStore.addSavedContact({ ...contact, phoneNumbers: phoneData });
        userProfile.incerementCreditsUsage(1, CreditTypes.PHONE);

        if (setIsPhoneExists) {
          setIsPhoneExists(true);
        }
      } catch (e: unknown) {
        userProfile.toggleUpgradePopup(UpgradeTypes.PHONE, CreditTypes.PHONE);
        eventEmmiter.emmit('toggle-popup');
      } finally {
        setIsLoading(false);
        onCloseHandler();
      }
    };

    const onCopyIconHandler = (value: string) => {
      const isCopied = copyInBuffer(value);
      if (!isCopied) return;
      onCloseHandler();
      notify('Phone is copied', { type: 'success' });
    };

    const onPhoneNumberClickHandler = () => {
      if (!numberRef.current) return;
      const range = document.createRange();
      range.selectNodeContents(numberRef.current);
      const selection = window.getSelection();
      if (!selection) return;
      selection.removeAllRanges();
      selection.addRange(range);
    };

    const open = Boolean(anchorEl);

    if (!isTable && !phone) {
      return (
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="stretch"
          sx={(theme) => {
            return isEmailExist
              ? {
                  borderTop: `1px solid ${theme.palette.customGray1.light}`,
                  p: 16,
                }
              : {};
          }}
        >
          <Button
            variant="contained"
            color={isEmailExist ? 'primary' : 'secondary'}
            startIcon={isEmailExist ? <WhitePhoneIcon /> : <PhoneIcon />}
            onClick={onRequestNumberHandler}
            disabled={isLoading}
            sx={{ width: '100%' }}
          >
            {isEmailExist ? 'Get access to phone (1 credit)' : 'Get access to phone'}
          </Button>
        </Stack>
      );
    }

    if (isPhoneOpened && !isTable && phone) {
      return (
        <>
          {phone.map((p) => (
            <PhoneInProfile
              key={p}
              phone={p}
              isEmailExist={isEmailExist}
              onCopyPhone={onCopyIconHandler}
            />
          ))}
        </>
      );
    }

    return (
      <>
        <SpinButton
          icon={PhoneIcon}
          isOpen={!!phone}
          onClick={onPhoneClickHandler}
          isLoading={isLoading}
          isExists={phone?.[0] ? true : false}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={onCloseHandler}
          PaperProps={{
            sx: {
              boxShadow: '0px 3px 8px 2px rgba(111, 113, 122, 0.2)',
              borderRadius: (theme) => theme.spacing(8),
              mt: 6,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack p={16}>
            {!phone ? (
              <BeforePhone onClick={onRequestNumberHandler} />
            ) : (
              <Stack gap={2} onClick={(e) => e.stopPropagation()}>
                <Typography color="text.secondary" variant="body2" textTransform="uppercase">
                  Mobile Number
                </Typography>
                <Stack direction="row" alignItems="center" gap={8}>
                  {phone?.[0] ? (
                    <>
                      <Typography
                        variant="h4"
                        textTransform="uppercase"
                        fontWeight={600}
                        ref={numberRef}
                        onClick={onPhoneNumberClickHandler}
                      >
                        {phone?.[0]}
                      </Typography>
                      <CopyIconButton
                        onClick={() => onCopyIconHandler(phone?.[0] || '')}
                        title="Copy phone"
                      />
                    </>
                  ) : (
                    <Stack direction="row" gap={8} alignItems="center">
                      <Typography
                        variant="subtitle2"
                        color="text.primary"
                        ref={numberRef}
                        onClick={onPhoneNumberClickHandler}
                      >
                        There is no phone
                      </Typography>
                      <Tooltip title="We do not take credit for this operation.">
                        <InfoOutlinedIcon color="disabled" fontSize="small" />
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Popover>
      </>
    );
  }
);

const BeforePhone = (props: ButtonProps) => {
  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };
  return (
    <Box onClick={(e) => handleStopPropagation(e)}>
      <Typography color="text.secondary" variant="body2" textTransform="uppercase">
        Mobile Number (1 credit)
      </Typography>
      <Typography variant="h4" textTransform="uppercase" mt={2} mb={24} fontWeight={600}>
        ***_***_***_**
      </Typography>
      <Button
        variant="contained"
        sx={{
          py: 8,
        }}
        {...props}
      >
        Request mobile number
      </Button>
    </Box>
  );
};

export function PhoneInProfile({
  phone,
  onCopyPhone,
  isEmailExist,
}: {
  phone: string;
  onCopyPhone: (value: string) => void;
  isEmailExist: boolean;
}) {
  const numberRef = useRef<null | HTMLParagraphElement>(null);

  const onPhoneNumberClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!numberRef.current) return;
    const range = document.createRange();
    range.selectNodeContents(numberRef.current);
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      px={16}
      gap={8}
      sx={[
        isEmailExist && {
          '&:last-child': {
            pb: 16,
          },
        },
      ]}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => {
          return {
            width: 44,
            height: 44,
            backgroundColor: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.customGray1.light}`,
            borderRadius: '50%',
          };
        }}
      >
        <BlackPhoneIcon />
      </Stack>
      {!phone ? (
        <Stack direction="row" gap={8} alignItems="center">
          <Typography
            variant="subtitle2"
            color="text.primary"
            ref={numberRef}
            onClick={onPhoneNumberClickHandler}
          >
            There is no phone
          </Typography>
          <Tooltip title="We do not take credit for this operation.">
            <InfoOutlinedIcon color="disabled" fontSize="small" />
          </Tooltip>
        </Stack>
      ) : (
        <Stack>
          <Stack direction="row" alignItems="center" gap={8}>
            <Typography
              variant="subtitle2"
              color="primary.light"
              ref={numberRef}
              onClick={onPhoneNumberClickHandler}
            >
              {phone}
            </Typography>
            <CopyIconButton title="Copy email" onClick={() => onCopyPhone(phone)} />
          </Stack>
          <Typography variant="subtitle2" color="customGray2.dark">
            Personal
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
