import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import { contactService, ICountry, IIndustry } from '@shared/api/services/contact-service';
import { useEffect, useState } from 'react';
import { useListController } from 'react-admin';

import { ReactComponent as BagIcon } from '../../../shared/assets/icons/bag.svg';
import { ReactComponent as ManIcon } from '../../../shared/assets/icons/black-man.svg';
import { ReactComponent as IndustryIcon } from '../../../shared/assets/icons/industry-building.svg';
import { ReactComponent as LocationIcon } from '../../../shared/assets/icons/location-marker.svg';
import FilterItem from './filter-items/filter-item';
import FullNameFilter from './filter-items/full-name-filter';
import IndustryFilter from './filter-items/industry-filter';
import JobTitleFilter from './filter-items/job-title-filter';
import LocationFilter from './filter-items/location-filter';
import { useContactFilter } from './hooks/filter-context';
import { ContactFilterItemProps } from './interfaces';

const ContactFilters = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [countryList, setCountryList] = useState<ICountry[]>([]);

  const [industryList, setIndustryList] = useState<IIndustry[]>([]);

  const { isFetching } = useListController();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const [country, industry] = await Promise.allSettled([
          contactService.getCountries(),
          contactService.getIndustries(),
        ]);
        if (country.status === 'fulfilled') setCountryList(country.value);
        if (industry.status === 'fulfilled') setIndustryList(industry.value);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, []);

  const fields: ContactFilterItemProps[] = [
    { title: 'Name', icon: ManIcon, Filter: FullNameFilter },
    { title: 'Job title', icon: BagIcon, Filter: JobTitleFilter },
    { title: 'Location', icon: LocationIcon, Filter: LocationFilter, countryList, isLoading },
    { title: 'Industry', icon: IndustryIcon, Filter: IndustryFilter, industryList, isLoading },
  ];

  return (
    <Stack
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      height="100%"
    >
      <Box flexGrow={1}>
        <FilterHeader />
        <Stack
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            borderTop: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            '& .contact-filter-item:not(:last-child)': {
              borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            },
          }}
          px={24}
        >
          {fields.map((filter) => (
            <FilterItem {...filter} key={filter.title} />
          ))}
        </Stack>
      </Box>
      <Stack px={20} pb={32}>
        <ApplyButton type="submit" disabled={isFetching} />
      </Stack>
    </Stack>
  );
};

function FilterHeader() {
  const { activeFiltersQuantity, resetAll } = useContactFilter();
  return (
    <Stack px={24} mb={16} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={4}>
        <Typography variant="h5">Filters</Typography>
        {!!activeFiltersQuantity && (
          <Box
            px={12}
            borderRadius={30}
            height={20}
            sx={{
              bgcolor: 'customGray2.main',
            }}
          >
            <Typography variant="body2">{activeFiltersQuantity}</Typography>
          </Box>
        )}
      </Stack>
      {!!activeFiltersQuantity && <Button onClick={resetAll}>Clear filter</Button>}
    </Stack>
  );
}

function ApplyButton({ disabled, ...props }: ButtonProps) {
  const { applyFilter, isApplyButtonDisabled } = useContactFilter();
  return (
    <Button
      variant="contained"
      disabled={isApplyButtonDisabled || disabled}
      {...props}
      onClick={() => {
        applyFilter?.();
      }}
    >
      Apply filters
    </Button>
  );
}

export default ContactFilters;
