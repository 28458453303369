import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useListController } from 'react-admin';

interface ContactFilterContext {
  filters: {
    name?: string | null;
    type?: string | null;
    primary_role?: string | null;
    city?: string | null;
    region?: string | null;
    country?: string | null;
    country_code?: string | null;
    description?: string | null;
    country_code_id?: string | null;
  };
  setFilterInputs: Dispatch<SetStateAction<ContactFilterContext['filters']>>;
  applyFilter: () => void;
  resetAll: () => void;
  activeFiltersQuantity: number;
  isApplyButtonDisabled: boolean;
}

const Context = createContext<Partial<ContactFilterContext>>({});

const CompanyFilterProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setFilters, filterValues } = useListController();

  const [filterInputs, setFilterInputs] = useState<ContactFilterContext['filters']>(() => ({
    name: filterValues.name || null,
    type: filterValues.type || null,
    primary_role: filterValues.primary_role || null,
    city: filterValues.city || null,
    region: filterValues.region || null,
    country: filterValues.country || null,
    country_code: filterValues.country_code || null,
    description: filterValues.description || null,
    country_code_id: filterValues.country_code_id || null,
  }));

  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);

  useEffect(() => {
    let isButtonDisabled = true;
    Object.entries(filterInputs).forEach(([key, value]) => {
      const urlFilterValue = filterValues[key];
      if (!urlFilterValue && value !== null) isButtonDisabled = false;
      if (urlFilterValue && value !== urlFilterValue) isButtonDisabled = false;
    });
    setIsApplyButtonDisabled(isButtonDisabled);
  }, [filterInputs, filterValues]);

  const resetAll = () => {
    const resetFilters: Partial<ContactFilterContext['filters']> = {};
    Object.keys(filterInputs).forEach((key) => {
      resetFilters[key as 'name'] = null;
    });
    setFilterInputs(resetFilters);
    setFilters({}, {});
    setIsApplyButtonDisabled(true);
  };

  const activeFiltersQuantity = Object.entries(filterValues).reduce((acc, filter) => {
    return acc + +Boolean(filter);
  }, 0);

  const applyFilter = useCallback(() => {
    const newValues: Record<string, unknown> = {};
    Object.entries(filterInputs).forEach(([key, value]) => {
      if (value) newValues[key] = value;
    });
    setFilters(newValues, {});
  }, [filterInputs, setFilters]);

  const contextValue: ContactFilterContext = {
    filters: filterInputs,
    setFilterInputs,
    applyFilter,
    resetAll,
    activeFiltersQuantity,
    isApplyButtonDisabled,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useCompanyFilter = () => useContext(Context);

export { CompanyFilterProvider };
