import { Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLogout } from 'react-admin';

import landingURL from '../../../shared/api/landingURL';
import { ReactComponent as LogoutIcon } from '../../../shared/assets/icons/iconLogout.svg';
import { InputField } from '../../../shared/ui/inputs';
import { useUserProfile } from '../model/user-profile.store';
import PaymentCard from '../payment-card/payment-card';
// import CardButton from '../payment-card/payment-card-button'

function AccountInfo() {
  const userProfile = useUserProfile();
  const logout = useLogout();
  const logoutUser = async () => {
    logout({}, landingURL || 'https://bruxt.com');
  };

  const onDeleteCard = async () => {
    await userProfile.deleteCardInfo();
    await userProfile.getCardInfo();
  };

  return (
    <Stack minWidth={490} borderRadius="16px" border="1px solid #E7E8EF">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={9}
        px={16}
        py={12}
        borderBottom="1px solid #E7E8EF"
      >
        <Typography variant="h4" fontWeight={700} color="text.primary">
          Account info
        </Typography>
        <Stack
          direction="row"
          gap={8}
          alignItems="center"
          onClick={() => logoutUser()}
          sx={{ cursor: 'pointer' }}
        >
          <LogoutIcon />
          <Typography variant="subtitle1" color="text.primary">
            Log out
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="column" justifyContent="center" px={16}>
        <Stack
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
          py={16}
          gap={16}
        >
          <Stack gap={8} width="100%">
            <Typography variant="subtitle1" color="text.primary">
              First Name
            </Typography>
            <InputField
              value={userProfile.currentProfile?.firstName}
              disabled={true}
              sx={{
                '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgba(0, 0, 0, 0.26)',
                  },
                '& .MuiOutlinedInput-input.Mui-disabled': {
                  ':hover': {
                    border: '',
                  },
                  WebkitTextFillColor: '#000000',
                },
              }}
            />
          </Stack>
          <Stack gap={8} width="100%">
            <Typography variant="subtitle1" color="text.primary">
              Last Name
            </Typography>
            <InputField
              value={userProfile.currentProfile?.lastName}
              disabled={true}
              sx={{
                '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgba(0, 0, 0, 0.26)',
                  },
                '& .MuiOutlinedInput-input.Mui-disabled': {
                  ':hover': {
                    border: '',
                  },
                  WebkitTextFillColor: '#000000',
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #E7E8EF"
          sx={{ ':last-of-type': { borderBottom: 'none' } }}
          py={12}
          gap={16}
        >
          <Typography variant="subtitle1" color="text.primary">
            {userProfile.currentProfile?.email}
          </Typography>
          {/* <Stack direction="row" gap={4} justifyContent="center" alignItems="center">
            <ChangeIcon />
            <Typography variant="subtitle1" color="primary.main">
              Change email
            </Typography>
          </Stack> */}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #E7E8EF"
          sx={{ ':last-of-type': { borderBottom: 'none' } }}
          py={12}
          gap={16}
        >
          {
            userProfile.cardInfo ? (
              <PaymentCard
                cardType={userProfile?.cardInfo?.brand ?? ''}
                lastFourDigits={userProfile?.cardInfo?.last4 ?? ''}
                onDelete={onDeleteCard}
              />
            ) : (
              <></>
            )
            // <CardButton buttonText='Add card' onClick={()=>{}}/>
          }
        </Stack>

        {/* <Stack direction="row" justifyContent="space-between" alignItems="center" py={12} gap={16}>
          <Typography variant="subtitle1" color="text.primary">
            ***********
          </Typography>
          <Stack direction="row" gap={4} justifyContent="center" alignItems="center">
            <ChangeIcon />
            <Typography variant="subtitle1" color="primary.main">
              Change password
            </Typography>
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  );
}

export default observer(AccountInfo);
