import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';

const centerButtonsStyle = {
  display: 'flex',
  justifyContent: 'center',
};

interface PopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  text: string;
}

const Popup: React.FC<PopupProps> = ({ open, onClose, onConfirm, onCancel, text }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>{text}</DialogContent>
      <DialogActions style={centerButtonsStyle}>
        <Button onClick={onCancel} color="primary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
