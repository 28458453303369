import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import LeftSide from '@pages/contacts/left-side/left-side';
import { RightSide } from '@pages/contacts/right-side/right-side';
import { IContact } from '@shared/api/services/contact-service';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './ContactSidebar.module.scss';

export const ContactSidebar: FC<{ contact: IContact; updating: boolean }> = observer(
  ({ contact, updating }) => {
    if (!contact) return null;

    return (
      <Box height="100%" overflow="hidden">
        <Stack
          className={styles.scrollbar}
          direction="column"
          gap={16}
          maxWidth={492}
          bgcolor="white"
          p={16}
          pt={40}
          boxShadow="-4px 0px 20px rgba(111, 113, 122, 0.2)"
          position="relative"
          height="100vh"
        >
          {updating && (
            <Stack direction="row" gap={8} alignItems="center">
              <Typography variant="subtitle1">Updating...</Typography>
              <CircularProgress size={20} />
            </Stack>
          )}
          <LeftSide key={contact.id} contact={contact} isSidebar={true} />
          <RightSide contact={contact} />
        </Stack>
      </Box>
    );
  }
);
