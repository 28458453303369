import { Box, Button, Stack, Typography } from '@mui/material';

import { ReactComponent as DocumentsIcon } from '../../shared/assets/icons/documents.svg';
import { ReactComponent as SearchIcon } from '../../shared/assets/icons/search.svg';
import { useContactFilter } from '../contacts/filters/hooks/filter-context';

export const NoResults = () => {
  const { resetAll } = useContactFilter();
  return (
    <Stack direction="column" height="100%" justifyContent="center" alignItems="center" gap={40}>
      <Stack gap={16} alignItems="center" maxWidth={368}>
        <Box sx={{ position: 'relative' }}>
          <DocumentsIcon />
          <Box
            sx={{
              position: 'absolute',
              top: '17%',
              left: '45%',
            }}
          >
            <SearchIcon />
          </Box>
        </Box>

        <Stack gap={8}>
          <Typography variant="h5" textAlign="center">
            No results found
          </Typography>
          <Typography variant="subtitle2" textAlign="center" maxWidth={300}>
            We couldn’t find what you searched for. Please try again.
          </Typography>
        </Stack>
      </Stack>
      <Button
        variant="text"
        sx={{ paddingX: 70, paddingY: 8, borderRadius: '8px', background: '#F5F6F9' }}
        onClick={resetAll}
      >
        <Typography variant="subtitle1" color="primary.main">
          Clear filters
        </Typography>
      </Button>
    </Stack>
  );
};
