import { Stack, Typography } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { Link } from 'react-admin';

import { SignUpForm } from '../../../features/auth/sign-up-form';
//import { GoogleButton } from '../../../shared/ui/buttons'
//import { AuthDivider } from '../../../shared/ui/dividers'

const SignUpPage = () => {
  return (
    <Stack justifyContent="space-between" flexGrow={1} gap={20}>
      <SignUpForm />
      {/* <AuthDivider />
      <GoogleButton title="Sign up with Google" /> */}
      <Typography variant="subtitle2" textAlign="center">
        Already have an account?{' '}
        <Typography color="primary.main" fontWeight={600} component="span">
          <Link to={AppRoutes.Login}>Login</Link>
        </Typography>
      </Typography>
    </Stack>
  );
};

export default SignUpPage;
