import { Button, Typography } from '@mui/material';
import { formatNumber } from '@shared/libs/formatNumber';
import { observer } from 'mobx-react-lite';

import { useContactsStore } from '../../../entities/contact-table';
import { ReactComponent as UploadIcon } from '../../../shared/assets/icons/upload.svg';
import { ReactComponent as UploadIconBlack } from '../../../shared/assets/icons/upload-black.svg';

// TODO get saved quantity from context
const SavedButton = ({ handleClick, isSaved }: { handleClick: () => void; isSaved: boolean }) => {
  const { savedContacts } = useContactsStore();
  return (
    <Button
      variant={isSaved ? 'contained' : 'outlined'}
      color="secondary"
      startIcon={isSaved ? <UploadIcon /> : <UploadIconBlack />}
      onClick={handleClick}
      sx={{
        px: 16,
        py: 4,
        height: 32,
        borderColor: isSaved ? 'none' : 'customGray1.light',
        borderRadius: (theme) => theme.spacing(26),
      }}
    >
      <Typography variant="subtitle1" color={isSaved ? 'primary.main' : 'text.primary'}>
        Saved ({formatNumber(savedContacts.length)})
      </Typography>
    </Button>
  );
};

export default observer(SavedButton);
