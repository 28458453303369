// import { IEducationHistory, IWorkHistory } from '../api/services/contact-service'

export const capitalizeWordsInString = (value: string) => {
  if (!value) return '';
  return value.replace(/\b\w/g, (letter) => letter.toUpperCase());
};

// export const capitalizeExpirience = (
//   obj: IEducationHistory | IWorkHistory,
//   func: (value: string) => string
// ) => {
//   for (let key in obj) {
//     if (typeof obj[key] === 'object') {
//       capitalizeExpirience(obj[key], capitalizeWordsInString)
//     } else if (typeof obj[key] === 'string') {
//       obj[key] = func(obj[key])
//     }
//   }
// }
