import { IContactDataLayout } from '@widgets/data-layouts/types';

import DataLayout from './data-layout';

const ListsLayout = ({ children, showSaved }: IContactDataLayout) => {
  return (
    <DataLayout header={null} filter={null} type={'lists'} show={showSaved}>
      {children}
    </DataLayout>
  );
};

export default ListsLayout;
