import { useMemo } from 'react';
import { useListController } from 'react-admin';

const useIsNoFilters = () => {
  const { filterValues } = useListController();

  const isFilterApplied = useMemo(() => {
    return !!Object.keys(filterValues).length;
  }, [filterValues]);

  return {
    isFilterApplied,
  };
};

export default useIsNoFilters;
