import { useSubscriptionStore } from '@entities/subscription';
import { Button, Stack, Typography } from '@mui/material';
import { PlanStatus } from '@shared/api/services/plan-service/plan.interfaces';
import { AppRoutes } from '@shared/interfaces';
import { getDateName } from '@shared/libs';
import { getNewDate } from '@shared/libs/getNewDate';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import StyledProgressBar from '../../../shared/ui/styled-progress-bar/styled-progress-bar';
import { useUserProfile } from '../model/user-profile.store';

function CreditsUsage() {
  const userProfile = useUserProfile();
  const subscription = useSubscriptionStore();

  const startDate = userProfile.currentSubscription
    ? userProfile.currentSubscription.startDate
    : userProfile.currentProfile?.balance.lastCreditReset;

  const endDate = userProfile.currentSubscription
    ? userProfile.currentSubscription.endDate
    : getNewDate(
        userProfile.currentProfile?.balance
          ? userProfile.currentProfile.balance.lastCreditReset
          : '',
        1
      );

  const startString = getDateName(startDate!, true);
  const endString = getDateName(endDate!, true);

  const emailRangeString = `${userProfile.currentProfile?.balance.emailCredits} of ${userProfile.currentProfile?.balance.availableEmailCredits}`;
  const phoneRangeString = `${userProfile.currentProfile?.balance.phoneCredits} of ${userProfile.currentProfile?.balance.availablePhoneCredits}`;

  const navigate = useNavigate();

  const onChangePlanClickHandler = () => {
    navigate(AppRoutes.ProfileSubscription);
  };

  return (
    <Stack minWidth={490} borderRadius="16px" border="1px solid #E7E8EF">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={9}
        px={16}
        py={12}
        borderBottom="1px solid #E7E8EF"
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant="h4" fontWeight={700} color="text.primary">
            Credits usage
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {`${startString} - ${endString}`}
          </Typography>
        </Stack>

        {(subscription.currentSubscription === null ||
          subscription.currentSubscription?.status === PlanStatus.CANCELED) && (
          <Button
            variant="text"
            sx={{
              paddingY: (theme) => theme.spacing(8),
              paddingX: (theme) => theme.spacing(42),
              borderRadius: (theme) => theme.spacing(8),
              backgroundColor: (theme) => theme.palette.secondary.main,
            }}
            onClick={() => onChangePlanClickHandler()}
          >
            <Typography
              variant="subtitle2"
              color="primary.main"
              alignItems="center"
              fontWeight={600}
            >
              Buy credits
            </Typography>
          </Button>
        )}
      </Stack>
      <Stack direction="column" justifyContent="center" px={16}>
        {/*<Stack
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
          py={16}
          gap={16}
        >
           <Stack width="100%" gap={16}>
            <Stack>
              <Typography variant="subtitle1" fontWeight={700} color="text.primary">
                Export credits
              </Typography>
              <Typography variant="subtitle2" color="text.primary">
                You have used{' '}
                <Typography variant="subtitle2" color="success.main" component="span">
                  {userProfile.currentProfile?.balance.exportCredits} of{' '}
                  {userProfile.currentProfile?.balance.availableExportCredits}
                </Typography>{' '}
                available export credits this billing cycle.
              </Typography>
            </Stack>
            <StyledProgressBar
              usedAmount={userProfile.currentProfile?.balance.exportCredits ?? 0}
              amount={userProfile.currentProfile?.balance.availableExportCredits ?? 0}
            />
          </Stack>
        </Stack> */}
        <Stack
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
          py={16}
          gap={16}
        >
          <Stack width="100%" gap={16}>
            <Stack>
              <Typography variant="subtitle1" fontWeight={700} color="text.primary">
                Email credits
              </Typography>
              <Typography variant="subtitle2" color="text.primary">
                You have used{' '}
                <Typography variant="subtitle2" color="success.main" component="span">
                  {emailRangeString}
                </Typography>{' '}
                available email credits this billing cycle.
              </Typography>
            </Stack>
            <StyledProgressBar
              usedAmount={userProfile.currentProfile?.balance.emailCredits ?? 0}
              amount={userProfile.currentProfile?.balance.availableEmailCredits ?? 0}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" py={16} gap={16}>
          <Stack width="100%" gap={16}>
            <Stack>
              <Typography variant="subtitle1" fontWeight={700} color="text.primary">
                Mobile number credits
              </Typography>
              <Typography variant="subtitle2" color="text.primary">
                You have used{' '}
                <Typography variant="subtitle2" color="success.main" component="span">
                  {phoneRangeString}
                </Typography>{' '}
                available mobile number credits this billing cycle.
              </Typography>
            </Stack>
            <StyledProgressBar
              usedAmount={userProfile.currentProfile?.balance.phoneCredits ?? 0}
              amount={userProfile.currentProfile?.balance.availablePhoneCredits ?? 0}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default observer(CreditsUsage);
