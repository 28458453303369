import { Box, Button, Stack, Typography } from '@mui/material';

interface IIntegration {
  title: string;
  image: string;
  description: string;
  className: string;
  connected: boolean;
  openModal: (name: string, image: string) => void;
}

const Integration = ({
  title,
  image,
  description,
  className,
  openModal,
  connected,
}: IIntegration) => {
  return (
    <Stack
      direction="row"
      gap={16}
      display="flex"
      alignItems="center"
      paddingBottom={8}
      borderBottom="1px solid #E7E8EF"
      className={className}
    >
      <Box
        component="img"
        src={image}
        width={48}
        height="auto"
        draggable="false"
        sx={{
          objectFit: 'contain',
          touchAction: 'none',
          userSelect: 'none',
          pointerEvents: 'none',
        }}
      ></Box>
      <Stack>
        <Typography fontSize={'1.1rem'} variant="h3">
          {title}
        </Typography>
        <Typography variant="h4">{description}</Typography>
      </Stack>
      <Button
        variant={connected ? 'outlined' : 'contained'}
        sx={{
          lineHeight: 1,
          flexBasis: '100px',
          flexGrow: '0',
          flexShrink: '0',
          height: 'fit-content',
          fontSize: '0.875rem',
          fontWeight: '600',
          minWidth: 'initial',
        }}
        onClick={() => openModal(title, image)}
      >
        {connected ? 'Disconnect' : 'Connect'}
      </Button>
    </Stack>
  );
};

export default Integration;
