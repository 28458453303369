import { Box } from '@mui/material';

export const DotDivider = () => {
  return (
    <Box
      component="div"
      sx={{ height: 3, width: 3, borderRadius: '50%', backgroundColor: '#B1B5C3' }}
    />
  );
};
