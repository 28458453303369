import { ChangeEvent, FC } from 'react';

import { InputField, PasswordField } from '../../../shared/ui/inputs';
import { ISecondStep } from './types';

const SecondStep: FC<ISecondStep> = ({ accountCred, setAccountCred }) => {
  const onAccountCredChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setAccountCred({ ...accountCred, [e.target.name]: e.target.value });
  };

  return (
    <>
      <InputField
        value={accountCred.email}
        onChange={onAccountCredChangeHandler}
        type="email"
        placeholder="Enter your email"
        title="Email"
        name="email"
      />
      <PasswordField
        value={accountCred.password}
        onChange={onAccountCredChangeHandler}
        placeholder="Enter your password"
        title="Password"
        name="password"
      />
    </>
  );
};

export default SecondStep;
