import { ICompanyLocation } from '@shared/api/services/company-service';

export const makeStringFromObjectLocationRegion = (obj: Omit<ICompanyLocation, 'city'>) => {
  let string = '';
  if (obj.region) {
    string += obj.region + ', ';
  }
  if (obj.country) {
    string += obj.country + ', ';
  }
  return string;
};
