enum FetchStates {
  PENDING = 'pending',
  FETCHING = 'fetching',
  DONE = 'done',
  ERROR = 'error',
}

enum CreditTypes {
  EXPORT = 'exportCredits',
  EMAIL = 'emailCredits',
  PHONE = 'phoneCredits',
}

enum UpgradeTypes {
  VIEW = 'viewing up to 100 contacts',
  EMAIL = 'email credits',
  PHONE = 'phone credits',
  EXPORT = 'export credits',
}
export { CreditTypes, FetchStates, UpgradeTypes };
