import { IUser } from '@shared/api/services/auth-service';
import { ICardInfo } from '@shared/api/services/card-info-service/card-info.interface';
import { cardInfoService } from '@shared/api/services/card-info-service/card-info.service';
import { IIntegration } from '@shared/api/services/integration-service/integration.interfaces';
import { integrationService } from '@shared/api/services/integration-service/integration.service';
import { IPlan } from '@shared/api/services/plan-service/plan.interfaces';
import { planService } from '@shared/api/services/plan-service/plan.service';
import { profileService } from '@shared/api/services/profile-service/profile.service';
import { ISubscription } from '@shared/api/services/subscription-service/subscription.interfaces';
import { subscriptionService } from '@shared/api/services/subscription-service/subscription.service';
import { CreditTypes, FetchStates, UpgradeTypes } from '@shared/interfaces';
import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
class UserProfileStore {
  currentProfile: IUser | null = null;
  currentSubscription: ISubscription | null = null;
  currentPlan: IPlan | null = null;
  fetchState: FetchStates = FetchStates.FETCHING;
  upgradeShow = false;
  upgradeInfo: UpgradeTypes | string = UpgradeTypes.VIEW;
  cardInfo: ICardInfo | null = null;
  integrations: IIntegration | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  *getProfile() {
    try {
      this.currentProfile = yield profileService.getProfile();
    } catch (error) {
      this.fetchState = FetchStates.ERROR;
    }
  }

  async getCardInfo() {
    this.cardInfo = await cardInfoService.getCard();
  }

  async deleteCardInfo() {
    await cardInfoService.deleteCard();
  }

  setUpgradeInfo(info?: UpgradeTypes, type?: CreditTypes) {
    if (type) {
      this.upgradeInfo = this.currentProfile!.balance[type] + ' ' + info;
      return;
    }
    this.upgradeInfo = info ?? '';
  }

  toggleUpgradePopup(info?: UpgradeTypes, type?: CreditTypes) {
    this.setUpgradeInfo(info, type);

    this.upgradeShow = !this.upgradeShow;
  }

  setFetchState(state: FetchStates) {
    this.fetchState = state;
  }

  incerementCreditsUsage(value: number, type: CreditTypes) {
    if (this.fetchState !== FetchStates.DONE) return;
    if (this.currentProfile?.balance) {
      this.currentProfile.balance[type] = this.currentProfile.balance[type] + value;
    }
  }

  getUserProfileData() {
    this.setFetchState(FetchStates.FETCHING);
    this.getSubscription();
    this.getProfile();
    this.getCardInfo();
    this.updateIntegrationData();
  }

  updateUserProfileData() {
    this.setFetchState(FetchStates.FETCHING);
    this.getSubscription();
    this.getProfile();
    this.getCardInfo();
    this.updateIntegrationData();
    if (!this.currentSubscription) {
      this.getActivePlan();
    }
    // if (this.currentSubscription?.status === 'waiting_for_payment') {
    //   console.log('waiting_for_payment');

    //   this.updateUserProfileData()
    // } else {
    //   console.log('NOT waiting_for_payment');

    //   this.getProfile()
    //   if (!this.currentSubscription) {
    //     this.getActivePlan()
    //   }
    // }
  }

  *updateIntegrationData() {
    try {
      this.integrations = yield integrationService.getIntegrationStatus();
    } catch (error) {
      this.fetchState = FetchStates.ERROR;
    }
  }

  *getActivePlan() {
    try {
      const plan: IPlan[] = yield planService.getActivePlan();

      this.currentPlan = plan.filter(
        (value) => value.grade === 'standard' && value.duration === 'month'
      )[0];

      this.fetchState = FetchStates.DONE;
    } catch (error) {
      this.fetchState = FetchStates.ERROR;
    }
  }

  *getSubscription() {
    try {
      const currentSub: ISubscription | string = yield subscriptionService.getCurrentSubscription();

      if (typeof currentSub === 'string') {
        this.fetchState = FetchStates.PENDING;
        this.getActivePlan();
      } else {
        this.currentSubscription = currentSub;
        this.fetchState = FetchStates.DONE;
      }
    } catch (e) {
      this.fetchState = FetchStates.ERROR;
    }
  }
}

const Context = createContext(new UserProfileStore());
export const userStore = new UserProfileStore();

export const useUserProfile = () => useContext(Context);

export default UserProfileStore;
