import { BasePlan } from '../subscription-service/subscription.interfaces';

export interface IPlan {
  createdAt: string;
  duration: string;
  emailCreditsAmount: number;
  exportCreditsAmount: number;
  grade: string;
  id: string;
  name: string;
  phoneCreditsAmount: number;
  price: string;
  status: string;
  type: string;
  updatedAt: string;
  basePlan: BasePlan | null;
}

export enum PlanStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
}
