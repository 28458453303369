import { Stack, Typography } from '@mui/material';
import { useRef } from 'react';

import { ReactComponent as BlackPhoneIcon } from '../../../shared/assets/icons/iconPhoneBlack.svg';
import { CopyIconButton } from '../../../shared/ui/buttons';

export function PhoneCompanyInProfile({
  phone,
  title,
  onCopyPhone,
  key,
}: {
  phone: string;
  title: string;
  onCopyPhone: (value: string) => void;
  key?: string | number;
}) {
  const numberRef = useRef<null | HTMLParagraphElement>(null);

  const onPhoneNumberClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (!numberRef.current) return;
    const range = document.createRange();
    range.selectNodeContents(numberRef.current);
    const selection = window.getSelection();
    if (!selection) return;
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <Stack key={key} direction="row" alignItems="center" gap={8}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={(theme) => {
          return {
            width: 44,
            height: 44,
            backgroundColor: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.customGray1.light}`,
            borderRadius: '50%',
          };
        }}
      >
        <BlackPhoneIcon />
      </Stack>
      <Stack>
        <Stack direction="row" alignItems="center" gap={8}>
          <Typography
            variant="subtitle2"
            color="primary.light"
            ref={numberRef}
            onClick={onPhoneNumberClickHandler}
          >
            {phone}
          </Typography>
          <CopyIconButton title="Copy phone" onClick={() => onCopyPhone(phone)} />
        </Stack>
        <Typography variant="subtitle2" color="customGray2.dark">
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
}
