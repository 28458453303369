import { Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

// import { AppRoutes } from '../../../shared/interfaces'
// import { DotDivider } from '../../../shared/ui/dividers'

const InfoBlock = ({ blockTitle, children }: { blockTitle: string; children: JSX.Element }) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      border="1px solid #E7E8EF"
      borderRadius="8px"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="left"
        p={16}
        pt={12}
        pb={12}
      >
        <Typography variant="h4" color="text.primary" fontWeight={700}>
          {blockTitle}
        </Typography>
        {/* <Stack direction="row" justifyContent="space-between" alignItems="center" gap={8}>
          <Tooltip title={country}>
            <Typography
              variant="subtitle2"
              textOverflow="ellipsis"
              maxWidth={200}
              overflow="hidden"
              whiteSpace="nowrap"
              color="text.primary"
            >
              {country}
            </Typography>
          </Tooltip>
        </Stack> */}
      </Stack>
      {children}
    </Stack>
  );
};

export default observer(InfoBlock);
