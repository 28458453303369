import { Button, Stack, Typography } from '@mui/material';
import { authService } from '@shared/api/services/auth-service';
import { contactService, ICountry } from '@shared/api/services/contact-service';
import { AppRoutes } from '@shared/interfaces';
import { isEmailValid } from '@shared/libs/validations';
import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router';

import landingURL from '../../../shared/api/landingURL';
import { BackButton } from '../../../shared/ui/buttons';
import FirstStep from './first-step';
import SecondStep from './second-step';
import { IAccountCred, ICredentials } from './types';

const SignUpForm = () => {
  const [credentials, setCredentials] = useState<ICredentials>({
    firstName: '',
    lastName: '',
    company: '',
    country: '',
  });

  const [accountCred, setAccountCred] = useState<IAccountCred>({
    email: '',
    password: '',
  });

  const [countries, setCountries] = useState<ICountry[]>([]);

  const [isSecondStep, setIsSecondStep] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const notify = useNotify();

  const navigate = useNavigate();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const fetchedCountries = await contactService.getCountries();
        setCountries(fetchedCountries);
      } catch {
        notify("Can't get countries, try later", {
          type: 'error',
        });
      }
    };
    getCountries();
  }, [notify]);

  const onContinueClickHandler = () => {
    const isInvalidCredentials = Object.values(credentials).some((cred) => !cred);
    if (isInvalidCredentials) {
      notify('Input right data', {
        type: 'error',
      });
      return;
    }
    setIsSecondStep(true);
  };

  const onSubmitHandler = async () => {
    const isValidAccountCred = isEmailValid(accountCred.email) && !!accountCred.password;
    if (!isValidAccountCred) {
      notify('Input valid password and email', {
        type: 'error',
      });
      return;
    }
    try {
      setIsLoading(true);
      await authService.registration({
        ...credentials,
        ...accountCred,
      });
      const urlParams = new URLSearchParams();
      urlParams.set('authFlowStep', 'signUp');
      urlParams.set('email', accountCred.email);

      navigate(`${AppRoutes.CheckEmail}?${urlParams.toString()}`);
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notify(e.response?.data?.message || 'Something went wrong, try later', {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onBackClickHandler = () => {
    if (isSecondStep) {
      setIsSecondStep(false);
    } else {
      window.location.replace(landingURL || 'https://bruxt.com');
    }
  };

  return (
    <Stack component="form">
      <Stack direction="row" mb={20}>
        <BackButton onClick={onBackClickHandler} />
      </Stack>
      <Stack gap={8} mb={48}>
        <Typography variant="h2">Sign up</Typography>
        <Typography variant="subtitle2">
          {
            "Become a member - you'll get free access to millions of contacts of potential customers."
          }
        </Typography>
      </Stack>
      <Stack gap={24} mb={30}>
        {isSecondStep ? (
          <SecondStep accountCred={accountCred} setAccountCred={setAccountCred} />
        ) : (
          <FirstStep
            credentials={credentials}
            setCredentials={setCredentials}
            countries={countries}
          />
        )}
      </Stack>
      <Stack gap={15}>
        {isSecondStep ? (
          <Button variant="contained" disabled={isLoading} onClick={onSubmitHandler}>
            Sign up
          </Button>
        ) : (
          <Button variant="contained" onClick={onContinueClickHandler}>
            Continue
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default SignUpForm;
