import { CompaniesDataGrid } from '@entities/companies-table';
import useCompaniesStore from '@entities/companies-table/model/companies-table.store';
import UpdateWrapper from '@entities/update-wrapper/update-wrapper';
import { CompanySidebar } from '@features/companies/sidebar/company-sidebar';
import { UpgradePopup } from '@features/contacts';
import { Drawer, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '@shared/assets/icons/iconClose.svg';
import CompaniesLayout from '@widgets/data-layouts/companies-layout';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { ListBase } from 'react-admin';

const CompaniesPage = () => {
  const companies = useCompaniesStore();

  const tableRef = useRef<null | HTMLTableElement>(null);
  const drawerRef = useRef<null | HTMLDivElement>(null);

  const isSideBarOpen = Boolean(companies.currentCompany);

  const onSideBarCloseHandler = () => {
    companies.setCurrentCompany(null);
  };

  useEffect(() => {
    const onOutsideClickHandler = (e: Event) => {
      if (
        tableRef.current &&
        drawerRef.current &&
        !tableRef.current.contains(e.target as HTMLElement) &&
        !drawerRef.current.contains(e.target as HTMLElement)
      ) {
        companies.setCurrentCompany(null);
      }
    };
    document.addEventListener('click', onOutsideClickHandler);
    return () => {
      document.removeEventListener('click', onOutsideClickHandler);
    };
  }, [companies]);

  const [, setUpdating] = useState(false);

  return (
    <>
      <UpgradePopup />
      <UpdateWrapper setUpdating={setUpdating} isOpen={isSideBarOpen}>
        <ListBase perPage={25} resource="companies">
          <CompaniesLayout>
            <CompaniesDataGrid ref={tableRef} />
          </CompaniesLayout>
        </ListBase>

        <Drawer
          anchor="right"
          open={isSideBarOpen}
          onClose={onSideBarCloseHandler}
          ref={drawerRef}
          variant="persistent"
          PaperProps={{
            sx: {
              width: '500px',
              boxShadow: '-4px 0px 20px rgba(111, 113, 122, 0.2)',
              overflowY: 'visible',
            },
          }}
        >
          <IconButton
            onClick={onSideBarCloseHandler}
            sx={{
              border: '2px solid #EFF0F1',
              position: 'absolute',
              padding: 6,
              top: 8,
              left: -16,
              zIndex: 20,
              backgroundColor: 'white',
              ':hover': {
                backgroundColor: '#F5F6F9',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <CompanySidebar id={companies.currentCompany?.id} />
        </Drawer>
      </UpdateWrapper>
    </>
  );
};

export default observer(CompaniesPage);
