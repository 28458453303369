import { capitalizeWordsInString } from '@shared/libs';
import axios, { AxiosInstance } from 'axios';

import apiURL from '../../baseURL';
import withAuthorization from '../../withAuth';
import {
  ICountry,
  IEducationHistory,
  IEmail,
  IExportedContactsResult,
  IIndustry,
  IUpdatedUserInfo,
  IWorkHistory,
} from './contact.interfaces';

const contactInstance = axios.create({
  baseURL: apiURL + 'contacts/',
});

class ContactService {
  authRequest: AxiosInstance;

  private requestInstance: AxiosInstance;

  constructor(authRequest: AxiosInstance, requestInstance: AxiosInstance) {
    this.authRequest = authRequest;
    this.requestInstance = requestInstance;
  }

  async getCountries(): Promise<ICountry[]> {
    const { data } = await this.requestInstance.get<ICountry[]>('countries');
    return data;
  }

  async getIndustries(): Promise<IIndustry[]> {
    const { data } = await this.authRequest.get<IIndustry[]>('contacts/industries');
    return data.map((industry) => ({ ...industry, name: capitalizeWordsInString(industry.name) }));
  }

  async saveEmail(id: string): Promise<IEmail[]> {
    const { data } = await this.authRequest.post<IEmail[]>(`contacts/${id}/save/email`);
    return data;
  }

  async savePhone(id: string): Promise<string[]> {
    const { data } = await this.authRequest.post<string[]>(`contacts/${id}/save/phone`);
    return data;
  }

  async updateCurrentUser(userLinkedIn: string): Promise<IUpdatedUserInfo | null> {
    const { data } = await this.authRequest.get<IUpdatedUserInfo | null>(
      `admin/contacts/${userLinkedIn}/actual`
    );

    return data;
  }

  async updateWorkHistory(contactId: string): Promise<IWorkHistory[]> {
    const { data } = await this.authRequest.get<IWorkHistory[]>(`contacts/${contactId}/job`);

    return data;
  }

  async updateEducationHistory(contactId: string): Promise<IEducationHistory[]> {
    const { data } = await this.authRequest.get<IEducationHistory[]>(
      `contacts/${contactId}/education`
    );

    return data;
  }

  async exportSelectedContacts(
    contacts: string[]
  ): Promise<{ file?: string; response?: IExportedContactsResult }> {
    const { data } = await this.authRequest.post<{
      file?: string;
      response?: IExportedContactsResult;
    }>(`export`, { ids: contacts });
    return data;
  }
}

export const contactService = new ContactService(withAuthorization, contactInstance);
