import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { integrationService } from '@shared/api/services/integration-service/integration.service';
import { sleep } from '@shared/libs';
import { useCallback, useEffect, useReducer, useState } from 'react';

import HubSpotImage from '../../../shared/assets/images/hubspot-logo.png';
import SalesForceImage from '../../../shared/assets/images/salesforce.png';
import Integration from './integration';
import IntegrationModal from './modal';

const useStyles = makeStyles({
  cell: {
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Integrations = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const userProfile = useUserProfile();
  const { integrations } = userProfile;

  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const login = useCallback(
    (name: string, code: string) => {
      (async function () {
        if (name === 'hubspot' || name === 'salesforce') {
          await integrationService.loginCRM(name, code);
          await sleep(500);
          userProfile.updateUserProfileData();
          forceUpdate();
        }
      })();
    },
    [userProfile]
  );

  const logout = useCallback(
    (name: string) => {
      (async function () {
        if (name === 'hubspot' || name === 'salesforce') {
          await integrationService.logoutCRM(name);
          await sleep(500);
          userProfile.updateUserProfileData();
          forceUpdate();
        }
      })();
    },
    [userProfile]
  );

  const handleClick = useCallback(
    (name: string, image: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isConnected = integrations?.[name.toLowerCase()];
      if (typeof isConnected === 'boolean') {
        if (isConnected) {
          logout(name.toLowerCase());
        } else {
          setOpen(true);
          setName(name);
          setImage(image);
        }
      }
    },
    [integrations, logout]
  );

  useEffect(() => {
    //delay same as user-profile + 200
    const timeOut = setTimeout(() => {
      const integrationName = localStorage.getItem('integration') ?? '';
      // eslint-disable-next-line no-restricted-globals
      const code = new URL(location.href).searchParams.get('code') ?? '';

      if (code) {
        if (
          ['salesforce', 'hubspot'].includes(integrationName) &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          integrations?.[integrationName] === false
        ) {
          login(integrationName, code);
        } else {
          localStorage.removeItem('integration');
        }
      }
    }, 1200);

    return () => {
      clearTimeout(timeOut);
    };
  }, [integrations, login]);

  return (
    <>
      <IntegrationModal open={open} setOpen={setOpen} name={name} image={image} />
      <Stack
        minWidth={490}
        borderRadius="16px"
        border="1px solid #E7E8EF"
        width="100%"
        padding={16}
        paddingBottom={6}
        gap={16}
      >
        <Integration
          title={'SalesForce'}
          image={SalesForceImage}
          description={
            'Find ideal prospects, enrich your records, clean up stale contacts, and bi-directionally\n' +
            'sync all activities.'
          }
          className={classes.cell}
          openModal={handleClick}
          connected={integrations?.salesforce ?? false}
        />
        <Integration
          title={'Hubspot'}
          image={HubSpotImage}
          description={
            'Our bi-directional sync and database of 200M+ business contacts makes Hubspot great at outbound & inbound.'
          }
          className={classes.cell}
          openModal={handleClick}
          connected={integrations?.hubspot ?? false}
        />
      </Stack>
    </>
  );
};

export default Integrations;
