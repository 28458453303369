import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';

import { InputField } from '../../../shared/ui/inputs';
import { credFields } from './sign-up.meta';
import { IFirstStep } from './types';

const FirstStep: FC<IFirstStep> = ({ credentials, setCredentials, countries }) => {
  const onCredentialsChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const onCountryChangeHandler = (e: SelectChangeEvent<string>) => {
    setCredentials({ ...credentials, country: e.target.value });
  };

  return (
    <>
      {credFields.map(({ field, title, placeholder }) => (
        <InputField
          key={title}
          value={credentials[field as 'firstName']}
          name={field}
          onChange={onCredentialsChangeHandler}
          title={title}
          placeholder={placeholder}
        />
      ))}
      <Stack gap={8}>
        <Typography variant="subtitle1">Country</Typography>
        <Select
          value={credentials.country!}
          onChange={onCountryChangeHandler}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography variant="subtitle2" color="customGray1.dark" component="span">
                  Select country
                </Typography>
              );
            }
            return selected;
          }}
        >
          {countries.map(({ name, id }) => (
            <MenuItem value={name} key={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </>
  );
};

export default FirstStep;
