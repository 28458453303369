import { OutlinedInput, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as Danger } from '../../assets/icons/danger.svg';
import { IInputField } from './types';

const InputField: FC<IInputField> = ({ title = '', errorText = '', ...rest }) => {
  if (!title) return <OutlinedInput {...rest} />;

  return (
    <Stack gap={8}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">{title}</Typography>
        {errorText && (
          <Stack direction="row" alignItems="center" gap={4}>
            <Danger />
            <Typography variant="body2" color="error.main">
              {errorText}
            </Typography>
          </Stack>
        )}
      </Stack>
      <OutlinedInput {...rest} />
    </Stack>
  );
};

export default InputField;
