import axios, { AxiosInstance } from 'axios';

import apiURL from '../../baseURL';
import TokenService from '../../tokenService';
import {
  IForgotResetPasswordDto,
  ILoginDto,
  IRegistrationDto,
  IResetPasswordDto,
  IVerificationDto,
} from './auth.dto';
import { ILoginResponse, IWithMessageResponse } from './auth.interfaces';

const authInstance = axios.create({
  baseURL: apiURL + 'auth/',
});

class AuthService {
  private requestInstance: AxiosInstance;

  constructor(requestInstance: AxiosInstance) {
    this.requestInstance = requestInstance;
  }

  async login(dto: ILoginDto): Promise<ILoginResponse> {
    const { data } = await this.requestInstance.post<ILoginResponse>('login', dto);
    TokenService.updateLocalAccessToken(data.accessToken);
    TokenService.updateLocalRefreshToken(data.refreshToken);
    return data;
  }

  async registration(dto: IRegistrationDto): Promise<string> {
    const { data } = await this.requestInstance.post<IWithMessageResponse>('registration', dto);
    return data.message;
  }

  async registrationResend(email: string): Promise<IWithMessageResponse> {
    const { data } = await this.requestInstance.post<IWithMessageResponse>('registration/resend', {
      email,
    });
    return data;
  }

  async forgotPassword(email: string): Promise<IWithMessageResponse> {
    const { data } = await this.requestInstance.post<IWithMessageResponse>('forgot-password', {
      email,
    });
    return data;
  }

  async forgotResetPassword(dto: IForgotResetPasswordDto): Promise<IWithMessageResponse> {
    const { data } = await this.requestInstance.post<IWithMessageResponse>(
      'forgot-password/reset',
      dto
    );
    return data;
  }

  async verification(dto: IVerificationDto): Promise<ILoginResponse> {
    const { data } = await this.requestInstance.post<ILoginResponse>('verification', dto);
    return data;
  }

  async resetPassword(dto: IResetPasswordDto): Promise<IWithMessageResponse> {
    const { data } = await this.requestInstance.post<IWithMessageResponse>('reset-password', dto);
    return data;
  }

  async getEmailByCode(code: string): Promise<string> {
    const { data } = await this.requestInstance.get<string>(`/email?verifyCode=${code}`);
    return data;
  }
}

export const authService = new AuthService(authInstance);
