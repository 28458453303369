import { useSubscriptionStore } from '@entities/subscription';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { eventEmmiter } from '@/app/App';

import { ReactComponent as SubIcon } from '../../../shared/assets/icons/iconSubscription.svg';

const NewSubscriptionPopup = () => {
  const subscription = useSubscriptionStore();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  useLayoutEffect(() => {
    const toggle = () => {
      setShow((prev) => !prev);
    };
    eventEmmiter.on('toggle-popup', toggle);

    return () => {
      eventEmmiter.off('toggle-popup', toggle);
    };
  }, []);

  const handleClose = () => {
    setShow((prev) => !prev);
  };

  const handleUpgrade = () => {
    setShow((prev) => !prev);
    navigate(AppRoutes.Profile);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Stack
          alignItems="center"
          justifyContent="center"
          p={24}
          pt={48}
          sx={{
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(197,190,249,0.6) 78%)',
            position: 'relative',
          }}
          minWidth={380}
        >
          <Stack justifyContent="center" alignItems="center">
            <Stack p={18} sx={{ backgroundColor: '#C5BEF9' }} borderRadius="50%" mb={32}>
              <SubIcon />
            </Stack>
          </Stack>
          <Stack gap={8} mb={48} alignItems="center" justifyContent="center">
            <Typography variant="h5" color="text.primary">
              Thank you for subscription!
            </Typography>
            <Typography variant="subtitle2" color="text.primary" maxWidth={290} textAlign="center">
              Your profile had been upgraded to {subscription.selectedPlan?.name} plan
            </Typography>
          </Stack>
          <Stack gap={16} justifyContent="stretch" width="100%">
            <Button variant="contained" onClick={() => handleUpgrade()}>
              Great!
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default observer(NewSubscriptionPopup);
