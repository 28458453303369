import { AppRoutes } from '@shared/interfaces';
import { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import authProvider from '../../providers/authProvider';

const AuthRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated: boolean = authProvider.isAuthenticated();
    if (!isAuthenticated) return;
    navigate(AppRoutes.Base);
  }, [navigate]);

  return <>{children}</>;
};

export default AuthRoute;
