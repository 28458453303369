export const getNewDate = (dateString: string, addMonth: number) => {
  if (!dateString.length) return '';

  const date = new Date(dateString);

  date.setMonth(date.getMonth() + addMonth);
  date.setDate(date.getDate() - 1);

  return date.toString();
};
